import axios from "axios";

import {
  GET_ALL_TESTIMONIAL_URL,
  USER_GET_ABOUTUS_DATA,
  USER_GET_CATEGORY_All_DATA,
  USER_GET_COUNSELING_DATA,
  USER_GET_DASHBOARD_DATA,
  USER_GET_FOOTER_DATA,
  USER_GET_FOUNDATION_DATA,
  USER_GET_HEADER_DATA,
  USER_GET_SECTORAL_DATA,
  CONTACT_DATA_MULTI,
  CONTRIBUTE_DATA_MULTI,
  COOKIES_DATA_MULTI,
  FARMING_DATA_MULTI,
  GRANT_DATA_MULTI,
  LOGIN_SIGNUP_DATA_MULTI,
  PROFILE_DATA_MULTI,
  USER_META_DATA_URL,
  USER_WEEKLY_THOUGHT_URL,
} from "../constants";
import { getCommonApiHeader } from "../../utils/util";
import { InnerJsonData } from "../../user/components/About/innerMockData";


export const getTop3Testdata = (params) => {
  return (dispatch, getState) => {
    const { CommonReducer } = getState();
    axios
      .get(GET_ALL_TESTIMONIAL_URL, {
        headers: {
          ...getCommonApiHeader(),
        },
        params
      })
      .then((response) => {
        if (response.data?.sectionData?.testimonials) {
          dispatch({
            type: "GET_TOP3_TEST",
            data: response.data?.sectionData?.testimonials,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getTopVideosHome = (params) => {
  return (dispatch, getState) => {
    const { CommonReducer } = getState();
    axios
      .get(USER_GET_CATEGORY_All_DATA, {
        headers: {
          ...getCommonApiHeader(),
        },
        params
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_TOP_VIDEOS_HOME",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getDashboardData = (type) => {
  let url = `${USER_GET_DASHBOARD_DATA}`;
  if (type === 'HEADER') {
    url = `${url}/header`;
  }
  if (type === 'FOOTER') {
    url = `${url}/footer`
  }
  return (dispatch, getState) => {
    axios
      .get(url, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_DASHBOARD_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getAboutUsData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_ABOUTUS_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_ABOUTUS_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};


export const getFoundationData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_FOUNDATION_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_FOUNDATION_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};



export const getCounselingData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_COUNSELING_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_COUNSELING_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getHeaderData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_HEADER_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_HEADER_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};
export const getFooterData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_FOOTER_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_FOOTER_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getSectoralData = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_GET_SECTORAL_DATA, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_SECTORAL_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getGrantDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(GRANT_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_GRANT_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getOrganicFarmingDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(FARMING_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_ORGANIC_FARMING_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getContributeDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(CONTRIBUTE_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_CONTRIBUTE_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getLoginDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(LOGIN_SIGNUP_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_LOGIN_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getSignUpDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(LOGIN_SIGNUP_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_SignUp_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getProfileDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(PROFILE_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_PROFILE_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getFoundationInnerDataAPI = () => {
  return (dispatch, getState) => {
    // axios
    // .get(USER_GET_GRANT_DATA, {
    //   headers: {
    //     ...getCommonApiHeader(),
    //   }
    // })
    // .then((response) => {
    //   if (response.data) {
    dispatch({
      type: "GET_FOUNDATION_INNER_DATA",
      data: InnerJsonData,
    });
    // }
    // })
    // .catch((err) => {
    //   dispatch(handleError(err));
    // });
  };
};

export const getContactUsLangDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(CONTACT_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_Contact_Lang_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getCookiesLangDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(COOKIES_DATA_MULTI, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_Cookies_Lang_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getMetaTagDataAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_META_DATA_URL, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_META_TAG_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getWeeklySubsCriptionAPI = () => {
  return (dispatch, getState) => {
    axios
      .get(USER_WEEKLY_THOUGHT_URL, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_WEEKLY_SUBSCRIPTION_DATA",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getvideoDetailsAPI = (data) => {
  return (dispatch, getState) => {
    axios
      .get("https://api.dyntube.com/v1/live/channels/embed/" + data, {
        headers: {
          ...getCommonApiHeader(),
        }
      })
      .then((response) => {
        if (response.data) {
          dispatch({
            type: "GET_VIDEO_ID_DETAILS",
            data: response.data,
          });
        }
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};


export const handleSuccess = (data) => {
  return {
    type: "SHOW_TOAST",
    data,
    toastType: "success",
  };
};
export const handleError = (error) => {
  return {
    type: "SHOW_TOAST",
    data: error,
    toastType: "error",
  };
};
