import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getSectoralData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";
import { getCategoryAllData } from "../../../redux/action/user/category";

function Esselerator({ getSectoralData, sectoralData, getCategoryAllData, sectionOrder, sectionData }) {
  const [topSecction, setTopSection] = useState({});
  const [esseleratorSection, setEsseleratorSection] = useState({});
  const [companiesSection, setCompaniesSection] = useState({});
  const [programmSection, setProgrammSection] = useState([]);
  const [focusAreasSection, setFocusAreasSection] = useState({});
  const [technologySection, setTechnologySection] = useState({});
  const [support, setSupport] = useState({});
  const [mentorSection, setMentorSection] = useState({});

  useEffect(() => {
    // 👇️ scroll to top on page load
    getSectoralData();
    getCategoryAllData();
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  const topVideos = sectionData && sectionData.scroll;

  useEffect(() => {
    if (sectoralData?.esselerator) {
      setTopSection(sectoralData?.esselerator?.topSection);
      setEsseleratorSection(sectoralData?.esselerator?.esseleratorSection);
      setCompaniesSection(sectoralData?.esselerator?.companiesSection);
      setProgrammSection(sectoralData?.esselerator?.programmSection);
      setFocusAreasSection(sectoralData?.esselerator?.focusAreasSection);
      setTechnologySection(sectoralData?.esselerator?.technologySection);
      setSupport(sectoralData?.esselerator?.support);
      setMentorSection(sectoralData?.esselerator?.mentorSection);
    }
  }, [sectoralData]);

  const [playing, setPlaying] = useState(true);
  const pauseVideo = () => {
    var iframes = document.querySelectorAll("iframe");
    for (let i = 0; i < iframes.length; i++) {
      if (iframes[i] !== null) {
        var temp = iframes[i].src;
        iframes[i].src = temp;
      }
    }
  };
  const handleCancel = () => {
    setPlaying(false);
    pauseVideo();
    setTimeout(() => setPlayerModal(false), 500);
    setPlayBtn(1);
    setVideoIcon("");
    setvideoChannelKey("");
  };
  const [isLoader, setIsLoader] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const [playBtn, setPlayBtn] = useState(1);
  const [videoIcon, setVideoIcon] = useState("");
  const [videoChannelKey, setvideoChannelKey] = useState("");
  const handleVideo = (record) => {
    setVideoIcon(record.icon);
    setvideoChannelKey(record.channelKey);
    setPlayerModal(true);
    setIsLoader(true);
    window.addEventListener("dyntubeReady", () => {
      setIsLoader(false);
      var player = dyntube.getPlayer(record.channelKey);
      player.play();
    });
  };

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = '!function (e, t, i) { if (void 0 === e._dyntube_v1_init) { e._dyntube_v1_init = !0; var a = t.createElement("script"); a.type = "text/javascript", a.async = !0, a.src = "https://embed.dyntube.com/v1.0/dyntube.js", t.getElementsByTagName("head")[0].appendChild(a) } }(window, document);';
    document.body.appendChild(s);
  }, [location]);

  return (
    <>
      <section className="grant-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Link to="/" className="grant-back-btn">
                <i className="bi bi-chevron-left"></i> {topSecction && topSecction.ctaText}
              </Link>
              <p className="h6">{topSecction?.title}</p>
              <p className="h1">{topSecction?.subTitle}</p>
              <p className="para w-75">{topSecction?.desc}</p>
              <div className="img-container">
                {playBtn ? <img src={require("../../../static/user/img/icons/play-round-text.svg").default} alt="Play Button" className="ply-btn" onClick={() => handleVideo(topVideos && topVideos.cards && topVideos.cards[1])} /> : null}
                <img src={require("../../../static/user/img/SectoralFocus/Entrepreneurship.png").default} alt="Hero" />
              </div>

              {playerModal && (
                <Modal centered closable={true} onCancel={handleCancel} visible={true} footer={null} width={1100} className="home-hero-video">
                  {isLoader && (
                    <div className="row mt-lg-5 mt-3">
                      <div className="col-12 text-center position-relative">
                        <video className="w-100" poster={videoIcon}></video>
                      </div>
                    </div>
                  )}
                  <div data-dyntube-key={videoChannelKey}></div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-2">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="SF-Title">{esseleratorSection?.title}</div>
            <div className="SF-SubTitle mb-3">{esseleratorSection?.subTitle}</div>
            <p className="SF-Para">{parseHtml(esseleratorSection?.desc)}</p>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="SF-Title">{companiesSection?.title}</div>
            <div className="zee-fade">
              <div className="zee-icon">
                <img src={require("../../../static/user/img/SectoralFocus/icons/ZeeTV.svg").default} alt="Zee TV" />
              </div>
              <div className="zee-icon">
                <img src={require("../../../static/user/img/SectoralFocus/icons/Zee5.svg").default} alt="Zee 5" />
              </div>
              <div className="zee-icon">
                <img src={require("../../../static/user/img/SectoralFocus/icons/ZeeMedia.svg").default} alt="Zee Media" />
              </div>
              <div className="zee-icon">
                <img src={require("../../../static/user/img/SectoralFocus/icons/ZeeMusic.svg").default} alt="Zee Music" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 pt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="SF-Title">{topSecction && topSecction.programme}</div>
          </div>
          <div className="col-md-4">
            <div className="prgrm-tab">
              <i>{programmSection?.[0]?.step}</i>
              <div className="prgrm-title">
                <img src={require("../../../static/user/img/SectoralFocus/icons/application.svg").default} alt="Application" />
                <span>{programmSection?.[0]?.text}</span>
              </div>
              <p>{parseHtml(programmSection?.[0]?.desc)}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prgrm-tab">
              <i>{programmSection?.[1]?.step}</i>
              <div className="prgrm-title">
                <img src={require("../../../static/user/img/SectoralFocus/icons/interview.svg").default} alt="Application" />
                <span>{programmSection?.[1]?.text}</span>
              </div>
              <p>{programmSection?.[1]?.desc}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prgrm-tab">
              <i>{programmSection?.[2]?.step}</i>
              <div className="prgrm-title">
                <img src={require("../../../static/user/img/SectoralFocus/icons/selection.svg").default} alt="Application" />
                <span>{programmSection?.[2]?.text}</span>
              </div>
              <p>{programmSection?.[2]?.desc}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prgrm-tab">
              <i>{programmSection?.[3]?.step}</i>
              <div className="prgrm-title">
                <img src={require("../../../static/user/img/SectoralFocus/icons/DeepDive.svg").default} alt="Application" />
                <span>{programmSection?.[3]?.text}</span>
              </div>
              <p>{programmSection?.[3]?.desc}</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prgrm-tab">
              <i>{programmSection?.[4]?.step}</i>
              <div className="prgrm-title">
                <img src={require("../../../static/user/img/SectoralFocus/icons/pitch.svg").default} alt="Application" />
                <span>{programmSection?.[4]?.text}</span>
              </div>
              <p> {programmSection?.[4]?.desc}</p>
            </div>
          </div>
        </div>
      </div>

      <section className="esslrtr-focus-sctn">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="esslrtr-title mb-4">{focusAreasSection?.title}</div>
            </div>
            <div className="col-md-4">
              <div className="esslrtr-items thm-1">
                <div className="img-wrp">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/play-button.svg").default} alt="Icon" />
                </div>
                <h3>{focusAreasSection?.areas?.[0]?.title}</h3>
                <p>{focusAreasSection?.areas?.[0]?.desc}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="esslrtr-items thm-2">
                <div className="img-wrp">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/graduate.svg").default} alt="Icon" />
                </div>
                <h3>{focusAreasSection?.areas?.[1]?.title}</h3>
                <p>{focusAreasSection?.areas?.[1]?.desc}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="esslrtr-items thm-3">
                <div className="img-wrp">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/loudSpeaker.svg").default} alt="Icon" />
                </div>
                <h3>{focusAreasSection?.areas?.[2]?.title}</h3>
                <p>{focusAreasSection?.areas?.[2]?.desc}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tech-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tech-title mb-4">{technologySection?.title}</div>
              <div className="tech-list">
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Analytics.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[0]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Blockchain.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[1]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/AR-VR.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[2]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Blockchain.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[3]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/InteractiveGaming.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[4]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Cybersecurity.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[5]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Internet-of-Things.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[6]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/OTT&Video.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[7]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/EcommerceIntegration.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[8]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Cloud.svg").default} alt="Technology" />
                  <span>{technologySection?.technology?.[9]?.title}</span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tech-title mt-5 mb-3">{technologySection?.subtechnologySection?.title} </div>
              <img src={require("../../../static/user/img/SectoralFocus/technology-arrow.png").default} alt="Technology-Arrow" className="d-none d-md-block m-auto" />
              <div className="tech-list">
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Analytics.svg").default} alt="Technology" />
                  <span>{technologySection?.subtechnologySection?.technology?.[0]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/Blockchain.svg").default} alt="Technology" />
                  <span>{technologySection?.subtechnologySection?.technology?.[1]?.title}</span>
                </div>
                <div className="tech-items">
                  <img src={require("../../../static/user/img/SectoralFocus/icons/AR-VR.svg").default} alt="Technology" />
                  <span>{technologySection?.subtechnologySection?.technology?.[2]?.title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="focus-area-section mt-0">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="focus-title mb-4">{parseHtml(support?.title)}</div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="focus-area flx">
                <img src={require("../../../static/user/img/SectoralFocus/icons/BusinessMentorship.svg").default} alt="Focus Area Icon" />
                <h2>{parseHtml(support?.periodSection?.[0]?.title)}</h2>
                <p className="text-center">{parseHtml(support?.periodSection?.[0]?.desc)}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="focus-area flx">
                <img src={require("../../../static/user/img/SectoralFocus/icons/TechnologyMentorship.svg").default} alt="Focus Area Icon" />
                <h2>{parseHtml(support?.periodSection?.[1]?.title)}</h2>
                <p className="text-center">{parseHtml(support?.periodSection?.[1]?.desc)}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="focus-area flx">
                <img src={require("../../../static/user/img/SectoralFocus/icons/AccessToTools.svg").default} alt="Focus Area Icon" />
                <h2>{parseHtml(support?.periodSection?.[2]?.title)}</h2>
                <p className="text-center">{parseHtml(support?.periodSection?.[2]?.desc)}</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="focus-area flx">
                <img src={require("../../../static/user/img/SectoralFocus/icons/BusinessSupport.svg").default} alt="Focus Area Icon" />
                <h2>{parseHtml(support?.periodSection?.[3]?.title)}</h2>
                <p className="text-center">{parseHtml(support?.periodSection?.[3]?.desc)}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mentors-section">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="mentors-title">{mentorSection?.title}</div>
              <div className="mentors-para">{mentorSection?.subTitle}</div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/PunitGoenka.png" alt="Punit-Goenka" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[0]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[0]?.designation}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/AmitGoenka.png" alt="Amit-Goenka" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[1]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[1]?.designation}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/AshokGoel.png" alt="Ashok-Goel" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[2]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[2]?.designation}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  const { UserCategoryReducer } = state;
  const { allCategoryData = {}, start = 0 } = UserCategoryReducer;

  return {
    ...state,
    ...allCategoryData,
    sectoralData: state?.CommonReducer?.sectoralData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectoralData: () => dispatch(getSectoralData()),
    getCategoryAllData: (data) => dispatch(getCategoryAllData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Esselerator);
