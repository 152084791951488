import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFooterData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";

const PrivacyPolicy = ({ getFooterData, footerData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFooterData();
  }, []);

  const privacyData = footerData && footerData.privacyData;
  return (
    <>
      <section className="cms-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="h1">{privacyData && privacyData.title}</div>
              <p className="para">{privacyData && privacyData.effectievDate}: Tuesday,24 Feb 2023 </p>
              {/* {privacyData && privacyData[0] && new Date(privacyData[0].updatedAt).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5 pb-3 pb-md-5">
          <div className="row">
            <div className="col-md-8 mx-auto">

              <div class="cms-title">{privacyData && privacyData.overview}:</div>
              <p>{privacyData && parseHtml(privacyData.content)}</p>



            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    footerData: state?.CommonReducer?.footerData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFooterData: () => dispatch(getFooterData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
