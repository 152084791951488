import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSectoralData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";

const VijayScholarship = ({ getSectoralData, sectoralData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth"
    // });
    getSectoralData();
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  const vijaySection = sectoralData?.vijayScholarshipSection;

  const style = {
    backgroundColor: "var(--theme-orange)",
  };
  return (
    <>
      <section className="grant-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Link to="/" className="grant-back-btn">
                <i className="bi bi-chevron-left"></i> {vijaySection && vijaySection.topSection && vijaySection.topSection.back}
              </Link>
              <p className="h1 w-75 mt-4">
                <span className="text-sach">{vijaySection && vijaySection.topSection && vijaySection.topSection.sach} </span>
                <span>{vijaySection && vijaySection.topSection && vijaySection.topSection.vijayScholaship}</span>
                <span className="text-sach">{vijaySection && vijaySection.topSection && vijaySection.topSection.guidelines}</span>
              </p>
              <p className="para w-75">{vijaySection && vijaySection.topSection && vijaySection.topSection.desc}</p>
              <div className="img-container">
                <img src={require("../../../static/user/img/SectoralFocus/Vijaya-Scholarship.png").default} alt="Hero Image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-5">
        <div className="row early-edu-description pb-0">
          <div className="col-md-12">
            <div className="dowld-sec border-0 justify-content-end">
              <span>{vijaySection && vijaySection.downloadSection && vijaySection.downloadSection.download}:</span>
              <a href="form/SACH-Vijaya-Application-form-2020-form.pdf" download className="btn btn-sach" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{vijaySection && vijaySection.downloadSection && vijaySection.downloadSection.ApplicationForm}</span>
              </a>
              <a href="form/SACH-Vijaya-Recommendation-form-2020-form.pdf" download className="btn btn-sach ms-3" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{vijaySection && vijaySection.downloadSection && vijaySection.downloadSection.recommendationForm}</span>
              </a>
            </div>
          </div>
          <div className="col-md-12">
            <div className="h2">{vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[0] && vijaySection.scholarshipCoverSection[0].title}</div>
            {vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[0] && parseHtml(vijaySection.scholarshipCoverSection[0].desc)}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row early-edu-description py-0">
          <div className="col-md-12">
            <div className="h2">{vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[1] && parseHtml(vijaySection.scholarshipCoverSection[1].title)}</div>
            {vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[1] && parseHtml(vijaySection.scholarshipCoverSection[1].desc)}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row early-edu-description py-0">
          <div className="col-md-12">
            <div className="h2">{vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[2] && parseHtml(vijaySection.scholarshipCoverSection[2].title)}</div>
            {vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[2] && parseHtml(vijaySection.scholarshipCoverSection[2].desc)}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row early-edu-description py-0">
          <div className="col-md-12">
            <div className="h2">{vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[3] && parseHtml(vijaySection.scholarshipCoverSection[3].title)}</div>
            {vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[3] && parseHtml(vijaySection.scholarshipCoverSection[3].desc)}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row early-edu-description py-0">
          <div className="col-md-12">
            <div className="h2">{vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[4] && parseHtml(vijaySection.scholarshipCoverSection[4].title)}</div>
            {vijaySection && vijaySection.scholarshipCoverSection && vijaySection.scholarshipCoverSection[4] && parseHtml(vijaySection.scholarshipCoverSection[4].desc)}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    ...state,
    sectoralData: CommonReducer.sectoralData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectoralData: () => dispatch(getSectoralData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VijayScholarship);
