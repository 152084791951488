import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { userPostSubscriptionApi } from "../../../redux/action/user/subscription";
import { getCurrentUserDetails, parseHtml } from "../../../utils/util";

const Subcriber = (props) => {
  const { newsSection, userPostSubscriptionApi, userEmailSubsData } = props;
  const [subsError, setSubsError] = useState("");
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const UserDetails = getCurrentUserDetails();
  localStorage.setItem("emailRequired", newsSection?.emailRequired);

  const onFinish = (data) => {
    if (UserDetails && UserDetails.user) {
      data.sachUserId = UserDetails.user.sachUserId;
    }
    data.status = "Subscribed";
    userPostSubscriptionApi(data);
    form.resetFields();
  };

  useEffect(() => {
    if (userEmailSubsData !== "") {
      if (userEmailSubsData === "400") {
        setShow(true);
        setSubsError("Your email ID already exist!");
      } else {
        setSubsError("");
      }
    }
  }, [userEmailSubsData]);

  const validateMessages = {
    required: "${label} " + `${newsSection?.emailRequired}`,
  };
  return (
    <>
      <section className="newsletter about-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="sach-title">
                <h1 className="f-700 fs-40 text-white text-center">{newsSection?.title} </h1>
                <p className="text-white text-center">{parseHtml(newsSection?.content)}</p>
              </div>
              <Form validateMessages={validateMessages} form={form} onFinish={onFinish}>
                <div className="row g-3 align-items-startr my-md-4 my-2">
                  <div className="col-lg-8">
                    <Form.Item name="emailId" className="mb-0" rules={[{ required: true, message: newsSection?.emailRequired }]}>
                      <input type="email" id="emailId" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$" className="form-control p-3 pe-5 transpInput" placeholder={newsSection?.emailPlaceholder} />
                    </Form.Item>
                    {show ? <span style={{ color: "red" }}> {subsError}</span> : null}
                  </div>
                  <div className="col-lg-4 col-sm-12 text-md-start text-center">
                    <button type="submit" className="btn btn-sach bg-sach w-100 justify-content-center">
                      {newsSection?.cta?.text}
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g id="Property 1=arrow-right">
                          <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z" />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { SubscriptionReducer } = state;
  return {
    userEmailSubsData: SubscriptionReducer.userEmailSubsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userPostSubscriptionApi: (data) => dispatch(userPostSubscriptionApi(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subcriber);
