import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFooterData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";

const TnC = ({ getFooterData, footerData }) => {
  useEffect(() => {
    getFooterData();
  }, []);
  const termsData = footerData && footerData.termsData;


  // const termsConditionData = termsData && termsData.tableData && termsData.tableData.filter((p) => p.status == "Active");

  return (
    <>
      <section className="cms-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="h1">{termsData && parseHtml(termsData.title)}</div>
              <p className="para">{termsData && parseHtml(termsData.effectievDate)}: Tuesday,24 Feb 2023
                {/* {termsConditionData && termsConditionData[0] && new Date(termsConditionData[0].updatedAt).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })} */}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5 pb-3 pb-md-5">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div class="cms-title">{termsData && parseHtml(termsData.mainContent)}</div>
              {termsData && parseHtml(termsData.content)}

              {/* {termsConditionData && termsConditionData[0] && parseHtml(termsConditionData[0].descriptionText)} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    footerData: state?.CommonReducer?.footerData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFooterData: () => dispatch(getFooterData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TnC);
