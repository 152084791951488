import axios from "axios";
import { json } from "react-router-dom";
import { getCommonApiHeader } from "../../../utils/util";
import { GET_RESOURCE_DATA_URL, POST_CONTACT_US, USER_CHANGE_MOBILE_URL, USER_VERIFY_MOBILE_URL } from "../../constants";
import { handleError, handleSuccess } from "../common";

export const contact = (data) => {
  return (dispatch, getState) => {
    dispatch(getContactDataRequest());
    axios
      .post(POST_CONTACT_US, data)
      .then((response) => {
        dispatch(getContactDataRespond(response?.data));
        dispatch(handleSuccess({ ...response?.data, message: 'Your query has been forwarded to respective team' }));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const resourceDataApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getContactDataRequest());
    axios
      .get(GET_RESOURCE_DATA_URL, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getResourceDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const postForm = (data) => {
  return (dispatch, getState) => {
    dispatch(getContactDataRequest());
    axios
      .post(POST_CONTACT_US, data)
      .then((response) => {
        if (response) {
          dispatch(handleSuccess({ ...response?.data, message: 'Your query has been forwarded to respective team' }));
        }
        dispatch(getContactDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const handleChangeMobileSendOtp = (data) => {
  return (dispatch, getState) => {
    dispatch(getContactDataRequest());
    axios
      .post(USER_CHANGE_MOBILE_URL, data,
        {
          headers: {
            ...getCommonApiHeader(),
          }
        }
      )
      .then((response) => {
        // dispatch(handleSuccess({ ...response, message: "One time password sent successfully!" }));
        dispatch({
          type: "OTP_FAILD_DATA",
          data: response
        });

      })
      .catch((err) => {
        // dispatch(handleError({ ...err, message: "Otp sent failed!" }));
        dispatch({
          type: "OTP_FAILD_DATA",
          data: "404"
        });
      });
  };
};

export const handleVerifyMobileOtp = (data) => {
  return (dispatch, getState) => {
    dispatch(getContactDataRequest());
    axios
      .post(USER_VERIFY_MOBILE_URL, data,
        {
          headers: {
            ...getCommonApiHeader(),
          }
        }
      )
      .then((response) => {
        dispatch(handleSuccess({ ...response, message: "Otp Match successfully!" }));
        dispatch({
          type: "OTP_VERIFY_FAILD_DATA",
          data: response
        });
      })
      .catch((err) => {
        dispatch(handleError({ ...err, message: "Otp failed!" }));
        dispatch({
          type: "OTP_VERIFY_FAILD_DATA",
          data: "404"
        });
      });
  };
};

export const getContactDataRequest = (data) => {
  return {
    type: "Contact_Data_REQUESTED",
  };
};

export const getContactPostDataRequest = (data) => {
  return {
    type: "Contact_POST_Data_REQUESTED",
  };
};

export const getResourceDataRespond = (data) => {
  return {
    type: "Resource_DATA_RESPONSE",
    data: data,
  };
};

export const getContactDataRespond = (data) => {
  return {
    type: "Contact_DATA_RESPONSE",
    data: data,
  };
};
