const initState = {
  isLoading: false,
  contributeData: [],
  countries: [],
  states: [],
  cities: [],
  error: null,
  createPaymentData: {},
  paymentStatus: {}
};
const ContributeReducer = (state = initState, action) => {
  const data = action?.data;
  switch (action?.type) {
    case "Contribute_Data_REQUESTED":
      return {
        ...state,
        isLoading: true,
      };
    case "Contribute_CREATE_Data_REQUESTED":
      return {
        ...state,
        isLoading: true,
      };

    case "Contribute_COUNT_REQUESTED":
      return {
        ...state,
        isLoading: true,
      };
    case "Contribute_CREATE_Data_REQUESTED":
      return {
        ...state,
        isLoading: true,
      };
    case "Contribute_DATA_RESPONSE":
      return {
        ...state,
        contributeData: data,
        isLoading: false
      };
      case "Contribute_COUNT_RESPONSE":
        return {
          ...state,
          tabsData: data.tabsData,
          isLoading: false
        };
      case "Contribute_GET_COUNTRIES_DATA":
      return {
        ...state,
        countries: data?.tableData,
      };
      case "Contribute_GET_STATES_DATA":
      return {
        ...state,
        states: data?.tableData,
      };
      case "Contribute_GET_CITIES_DATA":
      return {
        ...state,
        cities: data?.tableData,
      };
      case "Contribute_GET_PIN_DATA":
        return {
          ...state,
          pinData: data,
        };
      case "Contribute_CREATE_PAYMENT_ORDER":
      return {
        ...state,
        createPaymentData: data,
        isLoading: false
      };
      case "Contribute_Payment_status_REQUESTED": 
      return {
        ...state,
        isLoading: true
      };
      case "Contribute_Payment_status_RESPONSE": {
        return {
          ...state,
          isLoading: false,
          paymentStatus: data
        }
      }
    default:
      return state;
  }
};
export default ContributeReducer;
