const initState = {
    userSubsData: [],
    userEmailSubsData: [],
    error: null

};
const SubscriptionReducer = (state = initState, action) => {

    const data = action?.data;
    switch (action?.type) {
        case "SUBSCRIPTION_Data_REQUESTED":
            return {
                ...state,
            };
        case "USER_SUBSCRIPTION_DATA":
            return {
                ...state,
                userSubsData: data,
            };
        case "EMAIL_FAILD_DATA":
            return {
                ...state,
                userEmailSubsData: data,
            };
        default:
            return state;
    }
}

export default SubscriptionReducer;