const initState = {
  isLoading: false,
  contactData: [],
  resourceData: [],
  error: null,
  otpFaild: [],
  otpVerifyFaild: []
};
const ContactReducer = (state = initState, action) => {
  const data = action?.data;
  switch (action?.type) {
    case "Contact_Data_REQUESTED":
      return {
        ...state,
        isLoading: true,
      };
    case "Contact_DATA_RESPONSE":
      return {
        ...state,
        contactData: data,
        isLoading: false
      };
    case "Resource_DATA_RESPONSE":
      return {
        ...state,
        resourceData: data,
        isLoading: false
      };
    case "OTP_FAILD_DATA":
      return {
        ...state,
        otpFaild: data,
        isLoading: false
      };
    case "OTP_VERIFY_FAILD_DATA":
      return {
        ...state,
        otpVerifyFaild: data,
        isLoading: false
      };
    default:
      return state;
  }
};
export default ContactReducer;
