import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { submitLogin, resetLoginData } from "../../redux/action/login";
import { generateOTPCall, verifyOTPCall, submitBasicInfo, resetSignUpData } from "../../redux/action/signup";
import { showPopup } from "../../redux/action/common";
import { Link, useNavigate } from "react-router-dom";
import SachImage from "../../static/admin/img/sach.svg";
import SachLoader from "../Loader";
import { checkAuthentication, checkValidUsername, parseHtml } from "../../utils/util";
import SignUpPage from "../SignUpPage";
import "./style.css";
import { getLoginDataAPI, getSignUpDataAPI } from "../../redux/action/common-action";
import ForgotModal from "./forgotModal";
import SuccessfullModal from "./Successfull";

const Login = (props) => {
  const { onLoginSubmit, loginDetails, allowSignUp = true, commonData, showPopup, resetLoginData, getLoginDataAPI, loginJsonData } = props;
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [isUserEmpty, setIsUserEmpty] = useState(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
  const [password, setPassword] = useState("");
  const [isUsernameValid, setValidUsername] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (checkAuthentication()) {
      navigate("/");
      return;
    }
    resetLoginData();
    getLoginDataAPI();
    getSignUpDataAPI();
  }, []);

  const toggleHover = () => setHovered(!hovered);

  const onUsernameChange = (user) => {
    if (user != "") {
      // if (user != "" && checkValidUsername(user)) {
      setValidUsername(true);
      setIsUserEmpty(false);
    } else {
      setValidUsername(false);
    }
    if (loginDetails.isLoginFailed) {
      resetLoginData();
    }
    setUsername(user);
  };

  const handleKeyDown = (event) => {
    if (event.key == "Enter") {
      onClickLogin();
    }
  };
  const onPasswordChange = (password) => {
    if (password != "") {
      setIsPasswordEmpty(false);
    }
    if (loginDetails.isLoginFailed) {
      resetLoginData();
    }
    setPassword(password);
  };

  const onClickLogin = () => {
    let redirectUrl = null;
    var index = window.location.href.indexOf("redirect=");
    if (index !== -1) {
      redirectUrl = window.location.href.split("redirect=")[1];
    }
    if (isUsernameValid && username != "" && password != "" && !loginDetails.isLoginFailed) {
      onLoginSubmit({ userId: username, password, redirectUrl: redirectUrl }, navigate);
      // setUsername("");
      // setPassword("");
    }
    if (username == "") {
      // setIsUserEmpty(true);
    }
    if (password == "") {
      // setIsPasswordEmpty(true);
    }
    return;
  };

  const onClickSignUp = () => {
    showPopup("signUp", {});
    // navigate("/signup");
  };
  const renderSignUpPopup = (data) => {
    return (
      <Modal className="signUp-modal" centered visible={true} closable={false} onCancel={() => showPopup(null)} footer={null}>
        <SignUpPage {...props} />
      </Modal>
    );
  };
  if (loginDetails?.isLoading) {
    return <SachLoader />;
  }

  const carouselSection = loginJsonData && loginJsonData.login && loginJsonData.login.carouselSection;
  const loginContent = loginJsonData && loginJsonData.login && loginJsonData.login.loginContent;
  const forgotPassword = loginJsonData && loginJsonData.forgotPassword;

  const handleForgot = () => {
    setForgotModal(true);
    setSuccessModal(false);
  };

  const handleSuccess = () => {
    setForgotModal(false);
    setSuccessModal(true);
  };

  return (
    <div>
      <div className="login-container login-modal">
        <Link className="modal-close" to="/">
          <i className="bi bi-x"></i>
        </Link>
        <div className="row h-100">
          <div className="col-lg-4 col-md-4">
            <div className="loginModal-img">
              <h4 className="title text-light">{carouselSection && parseHtml(carouselSection.title)}</h4>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="row h-100 align-items-center">
              <div className="col-lg-5 col-md-5 mx-auto">
                <img src={SachImage} className="img-fluid mb-3" alt="Sach Icon" />
                <h4 className="title">{loginContent && parseHtml(loginContent.title)}</h4>
                <p className="para">{loginContent && parseHtml(loginContent.subTitle)}</p>
                <div className="col-12 sach-form">
                  <label className="form-label">{loginContent && loginContent.field1 && loginContent.field1.text}</label>
                  <input type="text" onKeyUp={(e) => (e.target.value = e.target.value.toUpperCase())} maxLength={10} className={`form-control text-uppercase ${loginDetails?.isLoginFailed || isUserEmpty ? "invalid" : ""} ${isUsernameValid ? "" : "invalid"}`} id="userID" value={username} onChange={(event) => onUsernameChange(event.target.value)} />
                  {!isUsernameValid && <div className="col-12 mt-1 error-msg">{loginContent && loginContent.field1 && loginContent.field1.errorMsg}</div>}
                </div>

                <div className="col-12 sach-form">
                  <label className="form-label">{loginContent && loginContent.field2 && loginContent.field2.text}</label>
                  <div className="pass-wrapper">
                    <input type={showPass ? "text" : "password"} className={`form-control pe-5 ${loginDetails?.isLoginFailed || isPasswordEmpty ? "invalid" : ""}`} id="password" value={password} onChange={(event) => onPasswordChange(event.target.value)} onKeyDown={(event) => handleKeyDown(event)} />
                    {!showPass ? <i onClick={() => setShowPass(!showPass)} className="bi bi-eye"></i> : <i onClick={() => setShowPass(!showPass)} className="bi bi-eye-slash"></i>}
                  </div>
                </div>
                <p className="details text-end my-2">
                  <a href="javascript:void(0);" onClick={(e) => handleForgot()}>
                    {forgotPassword && forgotPassword.title}
                  </a>
                </p>
                <div className="col-12 mt-4">
                  <button className={`btn-sach login-btn ${hovered ? "btn-hover" : "bg-sach-dark"}`} onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={onClickLogin}>
                    {loginContent && loginContent.cta && loginContent.cta.text}
                  </button>
                </div>
                {loginDetails?.isLoginFailed && <div className="col-12 mt-1 error-msg">{loginDetails?.loginData?.message || "Invalid username or password"}</div>}
                {allowSignUp && (
                  <p className="details">
                    {loginContent && loginContent.createAccount && loginContent.createAccount.text} <span onClick={onClickSignUp}>{loginContent && loginContent.createAccount && loginContent.createAccount.subTitle}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {successModal && <SuccessfullModal handleCancel={() => setSuccessModal(false)} forgotPassword={forgotPassword} />}
      {forgotModal && <ForgotModal handleCancel={() => setForgotModal(false)} handleSuccess={handleSuccess} forgotPassword={forgotPassword} />}
      {commonData?.popupType != "" && commonData?.popupType === "signUp" && renderSignUpPopup(commonData?.popupData)}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer, LoginReducer } = state;

  return {
    ...state,
    loginDetails: state?.LoginReducer,
    commonData: state?.CommonReducer,
    signUpData: state?.SignUpReducer,
    loginJsonData: CommonReducer.loginJsonData,
    signUpJsonData: CommonReducer.signUpJsonData,
  };
};

const mapDispatchToProps = (dispatch) => {
  // console.log('in state dis[atch', dispatch)
  return {
    onLoginSubmit: (data, navigate) => dispatch(submitLogin(data, navigate)),
    showPopup: (type, data) => dispatch(showPopup(type, data)),
    generateOTPCall: (data) => dispatch(generateOTPCall(data)),
    verifyOTPCall: (data) => dispatch(verifyOTPCall(data)),
    submitBasicInfo: (data, navigate, pathname) => dispatch(submitBasicInfo(data, navigate, pathname)),
    resetSignUpData: () => dispatch(resetSignUpData()),
    resetLoginData: () => dispatch(resetLoginData()),
    getLoginDataAPI: () => dispatch(getLoginDataAPI()),
    getSignUpDataAPI: () => dispatch(getSignUpDataAPI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
