import React from "react";

function ThoughtOfTheDay() {
  return (
    <>
      <h1>ThoughtOfTheDay</h1>
    </>
  );
}

export default ThoughtOfTheDay;
