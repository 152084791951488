import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFooterData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";


const FAQ = ({ getFooterData, footerData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    document.querySelector("body").scrollTo(0, 0);
    // faqDataApi();
  }, []);
  useEffect(() => {
    getFooterData();
  }, []);
  const faqData = footerData && footerData.faqData;

  return (
    <>
      <section className="cms-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="h5">{faqData && faqData.title}</div>
              <div className="h1">{faqData && faqData.heading}</div>
              <p className="para">{faqData && faqData.subTitle}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="contactUs-form">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="helpCenter pb-4 pb-md-0">
                <span>{faqData && faqData.support}</span>
                <div className="h2">{faqData && faqData.faq}</div>
                <p>
                  {faqData && parseHtml(faqData.anySupport)}
                </p>
              </div>
            </div>
            <div className="col-md-9">
              <div class="sach-accordion-2 accordion" id="FAQ_Accordion">

                {faqData && faqData.questionAnswer.map((item, index) =>
                  <div class="accordion-item">
                    <button class={`accordion-button ${index == 0 ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${index}`} aria-expanded="false" aria-controls={`collapse_${index}`}>
                      {item && parseHtml(item.question)}</button>
                    <div id={`collapse_${index}`} class={`accordion-collapse ${index == 0 ? "show" : "collapse"}`} data-bs-parent="#FAQ_Accordion">
                      <div class="accor_wrp">
                        <div> {item && parseHtml(item.answer)}
                        </div></div></div></div>
                )}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    footerData: state?.CommonReducer?.footerData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFooterData: () => dispatch(getFooterData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
