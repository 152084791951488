import axios from "axios";
import { getCommonApiHeader, setSessionKeyValue } from "../../utils/util";
import { FORGOT_PASSWORD, LOGIN_URL } from "../constants";
import { handleError, handleSuccess } from "./common";
export const submitLogin = (data, navigate) => {
  return (dispatch) => {
    dispatch(userLoginRequest());
    axios
      .post(LOGIN_URL, data, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(userLoginRespond(response?.data));
          if (response?.data?.access_token) {
            setSessionKeyValue("auth-token", response?.data?.access_token);
            setSessionKeyValue(
              "userDetails",
              JSON.stringify(response?.data.userDetails)
            );
            if (data.redirectUrl && data.redirectUrl.length > 0) {
              window.location.href = data.redirectUrl
            } else {
              navigate("/");
            }
            return;
          }
        } else {
          dispatch(userLoginFailed(response?.data));
        }
      })
      .catch((err) => {
        dispatch(userLoginFailed(err?.response));
      });
  };
};

export const forgotPasswordApi = (data, navigate) => {
  return (dispatch) => {
    // dispatch(userLoginRequest());
    axios
      .post(FORGOT_PASSWORD, data,
        {
          headers: {
            ...getCommonApiHeader(),
          }
        }
      )
      .then((response) => {
        dispatch({
          type: "FORGOT_PASSWORD_DATA",
          data: response
        });
      })
      .catch((err) => {
        dispatch({
          type: "FORGOT_PASSWORD_DATA",
          data: "404"
        });
        // dispatch(handleError({ ...err, message: "forgot password failed!" }));
      });
  };
};

export const userLoginRequest = (data) => {
  return {
    type: "LOGIN_REQUESTED",
  };
};

export const userLoginRespond = (data) => {
  return {
    type: "LOGIN_RESPONSE",
    data: data,
  };
};
export const userLoginFailed = (data) => {
  return {
    type: "LOGIN_FAILED",
    data: data,
  };
};

export const resetLoginData = () => {
  return {
    type: "RESET_LOGIN_DATA",
  };
};
