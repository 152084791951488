import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCookiesLangDataAPI } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";


const CookiePolicy = ({ getCookiesLangDataAPI, cookiesLangData }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    document.querySelector("body").scrollTo(0, 0);
    getCookiesLangDataAPI();
  }, []);

  const mainSection = cookiesLangData && cookiesLangData.mainSection;

  return (
    <>
      <section className="cms-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="h1">{mainSection && parseHtml(mainSection.title)}</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5 pb-3 pb-md-5">
          <div className="row">
            <div className="col-md-8 mx-auto">
              {mainSection && parseHtml(mainSection.desc)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    cookiesLangData: CommonReducer.cookiesLangData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCookiesLangDataAPI: () => dispatch(getCookiesLangDataAPI()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicy);
