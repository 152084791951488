const initState = {
  toastData: null,
  toastType: null,
  isUploadLoader: false,
  testData: [],
  showTop3Test: false,
  topVideos: {},
  dashboardData: {},
  aboutUsData: {},
  foundationData: {},
  headerData: {},
  footerData: {},
  counselingData: {},
  sectoralData: {},
  grantData: {},
  farmingData: {},
  contributeJsonData: {},
  loginJsonData: {},
  signUpJsonData: {},
  foundationInnerData: {},
  contactUsLangData: {},
  cookiesLangData: {},
  profileDataMulti: {},
  metaTagData: [],
  weeklyThoughtData: [],
  videoDetailsData: {}
};
const CommonReducer = (state = initState, action) => {
  const data = action.data;
  switch (action?.type) {
    case "OPEN_POPUP":
      return {
        ...state,
        popupType: action?.popupType,
        popupData: action?.data,
      };
    case "SHOW_TOAST":
      return {
        ...state,
        toastType: action?.toastType,
        toastData: action?.data,
      };
    case "RESET_TOAST":
      return {
        ...state,
        toastType: null,
        toastData: null,
      };
    case "TOGGLE_UPLOAD_LOADER":
      return {
        ...state,
        isUploadLoader: action.data,
      };
    case "SET_DATA":
      return {
        ...state,
        ...action?.data,
      };
    case "GET_TOP3_TEST":
      return {
        ...state,
        ...action?.data,
        testData: data,
        showTop3Test: true,
      };
    case "GET_TOP_VIDEOS_HOME":
      return {
        ...state,
        topVideos: data,
      };
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: data,
      };
    case "GET_ABOUTUS_DATA":
      return {
        ...state,
        aboutUsData: data,
      };
    case "GET_FOUNDATION_DATA":
      return {
        ...state,
        foundationData: data,
      };
    case "GET_HEADER_DATA":
      return {
        ...state,
        headerData: data,
      };
    case "GET_COUNSELING_DATA":
      return {
        ...state,
        counselingData: data,
      };
    case "GET_FOOTER_DATA":
      return {
        ...state,
        footerData: data,
      };
    case "GET_SECTORAL_DATA":
      return {
        ...state,
        sectoralData: data,
      };
    case "GET_GRANT_DATA":
      return {
        ...state,
        grantData: data,
      };
    case "GET_ORGANIC_FARMING_DATA":
      return {
        ...state,
        farmingData: data,
      };
    case "GET_CONTRIBUTE_DATA":
      return {
        ...state,
        contributeJsonData: data,
      };
    case "GET_LOGIN_DATA":
      return {
        ...state,
        loginJsonData: data,
      };
    case "GET_SignUp_DATA":
      return {
        ...state,
        signUpJsonData: data,
      };
    case "GET_FOUNDATION_INNER_DATA":
      return {
        ...state,
        foundationInnerData: data,
      };
    case "GET_Contact_Lang_DATA":
      return {
        ...state,
        contactUsLangData: data,
      };
    case "GET_Cookies_Lang_DATA":
      return {
        ...state,
        cookiesLangData: data,
      };
    case "GET_PROFILE_DATA":
      return {
        ...state,
        profileDataMulti: data,
      };
    case "GET_META_TAG_DATA":
      return {
        ...state,
        metaTagData: data,
      };
    case "GET_WEEKLY_SUBSCRIPTION_DATA":
      return {
        ...state,
        weeklyThoughtData: data,
      };
    case "GET_VIDEO_ID_DETAILS":
      return {
        ...state,
        videoDetailsData: data,
      };
    default:
      return state;
  }
};
export default CommonReducer;
