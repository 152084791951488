import { Form, Input, message } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../../../commons/TextEditor";
import { getBlogDetail, getBlogTableContent, handlePublishBlog, modifyBlog } from "../../../../redux/action/admin/blogMgmt";
import { fetchWriteMultiData } from "../../../../redux/action/user/blogs";
import { handlePublishBlog as UserhandlePublishBlog } from "../../../../redux/action/user/myBlogs";
import { CloseIcon } from "../../../icons";
// import { AdminRoutes } from "../../../routes";
import PreviewBlogModal from "./modals/PreviewBlogModal";
import PublishBlogModal from "./modals/PublishBlogModal";
import "./style.css";
const WriteBlog = (props) => {
  let navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const paramsObj = Object.fromEntries(params);
  const [writeBlogForm] = Form.useForm();

  const { handlePublishBlog, UserhandlePublishBlog, blogDetail, getBlogDetail, thumbnailURL, thumbnailBase, fromUser, fetchWriteMultiData, writeMultiInfo } = props;
  const [blogTitle, setBlogTitle] = useState("");
  const [blogBody, setBlogBody] = useState("");
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [previewBlogModal, setPreviewBlogModal] = useState(false);
  const [additionalData, setAdditionalData] = useState({});

  const finalhandlePublishBlog = fromUser ? UserhandlePublishBlog : handlePublishBlog;

  useEffect(() => {
    if (blogDetail) {
      if (!writeBlogForm.getFieldValue("blogTitle") && blogDetail.blogTitle) {
        setBlogTitle(blogDetail.blogTitle);
        writeBlogForm.setFieldValue("blogTitle", blogDetail.blogTitle);
      }
      if (blogDetail.blogBody) {
        setBlogBody(blogDetail.blogBody);
      }
    }
  }, [blogDetail]);

  useEffect(() => {
    fetchWriteMultiData();
    const params = new URLSearchParams(location.search);
    const paramsObj = Object.fromEntries(params);
    if (paramsObj.id) {
      getBlogDetail(paramsObj);
    }
    setBlogTitle("");
    setBlogBody("");
  }, []);
  useEffect(() => {
    const regex = /(<([^>]+)>)/ig;
   if(!blogBody.replace(regex, '')) {
    writeBlogForm.setFieldValue("blogBody", '');
   } else {
    writeBlogForm.setFieldValue("blogBody", blogBody);
   }
    
  }, [blogBody]);

  
  const validateMessages = {
    required: "${label} " + `${writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.required}`,
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} " + `${writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.between}` + "${min}" + `${writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.and}` + "${max}",
    },
  };

  const handlePreviewBlogModal = (val, data) => {
    setAdditionalData(data);
    setPreviewBlogModal(val);
  };

  const handlePreview = () => {
    writeBlogForm
      .validateFields()
      .then((d) => {
        setPreviewBlogModal(true);
      })
      .catch((err) => {
        message.error((writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.msgError) || "Please check the form fields");
      });
  };

  const handleSaveDraft = () => {
    writeBlogForm
      .validateFields()
      .then((d) => {
        const params = new URLSearchParams(location.search);
        const paramsObj = Object.fromEntries(params);
        finalhandlePublishBlog({
          status: "draft",
          blogTitle,
          blogBody,
          id: paramsObj?.id,
        });
        navigate(-1);
      })
      .catch((err) => {
        message.error((writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.msgError) || "Please check the form fields");
      });
  };

  const onFinish = (values) => {
    writeBlogForm
      .validateFields()
      .then((d) => {
        setShowPublishModal(true);
      })
      .catch((err) => {
        message.error((writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.msgError) || "Please check the form fields");
      });
  };

  const renderHeader = () => {
    return (
      <header className="page-header blog-header">
        <div className="container">
          <div className="row align-items-center py-2 g-0">
            <div className="col-lg-6 col-md-6 d-flex align-items-center">
              <h2 className="page-title mb-0">{(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.title) || "Write New Story"}</h2>
              <div className="dvdr mx-3"></div>
            </div>
            <div className="col-lg-6 col-md-6 text-center text-md-end ms-auto ms-auto">
              <button className={`btn-sach btn-sach-linear mt-3 mt-md-0`} type="button" onClick={handlePreview}>
                <span>{(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.previewText) || "Preview"}</span>
              </button>
              <button onClick={handleSaveDraft} className={`btn-sach btn-sach-linear mt-3 mt-md-0 ms-3`} type="button">
                <span>{(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.saveDraft) || "Save Draft"}</span>
              </button>
              <button className={`btn-sach bg-sach mt-3 mt-md-0 ms-3 border-0`} type="button" onClick={onFinish}>
                <span>{(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.publish) || "Publish"}</span>
              </button>
              <div
                className="close-btn-wrp"
                onClick={() => {
                  navigate(-1);
                  return;
                }}
              >
                <img className="closeBtn" src={CloseIcon} alt="Close Link" />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };

  return (
    <>
      {renderHeader()}
      <section className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <Form form={writeBlogForm} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} validateMessages={validateMessages} onFinish={onFinish}>
              <Form.Item
                label={(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.postTitle) || "Post Title"}
                name="blogTitle"
                rules={[
                  { required: true },

                  {
                    max: 50,
                    message: (writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.postError) || "Title should be upto 50 characters",
                  },
                  {
                    min: 10,
                    message: (writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.postTitleError) || "Title should be atleast 10 characters",
                  },
                ]}
              >
                <Input
                  style={{
                    fontSize: "36px",
                    border: "none",
                    padding: "0px 1px 10px",
                  }}
                  value={blogTitle}
                  className="blogTitle"
                  placeholder={(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.titlePlaceHolder) || "Enter your title here"}
                  onChange={(e) => setBlogTitle(e.target.value)}
                />
              </Form.Item>
              <Form.Item label={(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.titlePlaceHolder) || "Post Description"} name="blogBody" rules={[{ required: true }]}>
                <TextEditor blogId={paramsObj.id} value={blogBody} name="blogBody" setValue={setBlogBody} placeholder={(writeMultiInfo && writeMultiInfo.writeBlogSection && writeMultiInfo.writeBlogSection.editorPlaceHolder) || "Add here"} writeMultiInfo={writeMultiInfo}></TextEditor>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
      {showPublishModal && <PublishBlogModal fromUser={fromUser} blogTitle={blogTitle} blogBody={blogBody} handleCancel={() => setShowPublishModal(false)} handlePreviewBlogModal={handlePreviewBlogModal} handlePublishBlog={finalhandlePublishBlog} writeMultiInfo={writeMultiInfo} />}
      {previewBlogModal && <PreviewBlogModal fromUser={fromUser} handleCancel={() => setPreviewBlogModal(false)} blogTitle={blogTitle} blogBody={blogBody} tableContent={blogDetail?.tableContent} thumbnailURL={thumbnailURL} thumbnailBase={thumbnailBase} {...additionalData} writeMultiInfo={writeMultiInfo} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { BlogMgmtReducer, BlogReducer } = state;
  const { isLoading, blogDetail, thumbnailURL } = BlogMgmtReducer;
  return {
    isLoading,
    blogDetail,
    thumbnailURL,
    thumbnailBase: blogDetail?.thumbnail,
    writeMultiInfo: BlogReducer.writeMultiInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePublishBlog: (data) => dispatch(handlePublishBlog(data)),
    UserhandlePublishBlog: (data) => dispatch(UserhandlePublishBlog(data)),
    getBlogTableContent: (data) => dispatch(getBlogTableContent(data)),
    getBlogDetail: (data) => dispatch(getBlogDetail(data)),
    modifyBlog: (data) => dispatch(modifyBlog(data)),
    handleCtaPostApi: (data) => dispatch(handleCtaPostApi(data, getTabsData)),
    fetchWriteMultiData: () => dispatch(fetchWriteMultiData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WriteBlog);
