import { Form } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { forgotPasswordApi } from "../../redux/action/login";
import AdminModal from "../../user/components/common/AdminModal";

const ForgotModal = ({ handleCancel, forgotPasswordApi, forgotPasswordData, handleSuccess, forgotPassword }) => {
  const [form] = Form.useForm();
  const [forgotErrorMsg, setForgotErrorMsg] = useState("");
  const emailRequired = localStorage.getItem("emailRequired");
  useEffect(() => {
    if (forgotPasswordData != "") {
      if (forgotPasswordData == "404") {
        setForgotErrorMsg(forgotPassword && forgotPassword.emailError);
      } else {
        handleSuccess();
      }
    }
  }, [forgotPasswordData]);

  const onFinish = (data) => {
    forgotPasswordApi(data);
    // handleCancel();
  };
  return (
    <AdminModal onCancel={handleCancel} footer={null} closable={true}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <div className="modal-heading my-4">
          <h1>{forgotPassword && forgotPassword.resend}</h1>
          <span>{forgotPassword && forgotPassword.desc}</span>
        </div>

        {/* <div className="my-0">
                <div className="sach-check form-check d-inline-block">
                    <input className="form-check-input" type="radio" name="resetUP" id="userID" />
                    <label className="form-check-label" for="userID">User-ID</label>
                </div>
                <div className="sach-check form-check d-inline-block">
                    <input className="form-check-input" type="radio" name="resetUP" id="resetPass" />
                    <label className="form-check-label" for="resetPass">Password</label>
                </div>
            </div> */}

        <div className="col-lg-12 col-md-12 sach-form py-3">
          <Form.Item label={forgotPassword && forgotPassword.email} name="userEmail" rules={[{ required: true, message: emailRequired ? emailRequired : "Email is required" }]}>
            <input type="email" className="form-control" id="userEmail" />
          </Form.Item>
          <span style={{ color: "red" }}> {forgotErrorMsg}</span>
        </div>

        <button type="submit" data-bs-target="#UserPassReset" data-bs-toggle="modal" className="btn-sach bg-sach-dark justify-content-center w-100">
          <span className="py-1">{forgotPassword && forgotPassword.send}</span>
        </button>
      </Form>
    </AdminModal>
  );
};

const mapStateToProps = (state) => {
  const { LoginReducer } = state;

  return {
    forgotPasswordData: LoginReducer.forgotPasswordData,
  };
};

const mapDispatchToProps = (dispatch) => {
  // console.log('in state dis[atch', dispatch)
  return {
    forgotPasswordApi: (data) => dispatch(forgotPasswordApi(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotModal);
