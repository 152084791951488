import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { getCounselingData } from "../../../redux/action/common-action";
import { checkAuthentication, getCurrentUserDetails, parseHtml } from "../../../utils/util";
function Counseling(props) {
  const { counselingData, getCounselingData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [data, setData] = useState([]);
  const [key, setKey] = useState("sach-tab-1");
  const userDetails = checkAuthentication();

  useEffect(() => {
    getCounselingData();
  }, []);

  useEffect(() => {
    if (counselingData && counselingData) {
      setData(counselingData);
    }
  }, [counselingData]);

  const counselorCarousel = {
    margin: 0,
    loop: false,
    dots: false,
    autoplay: false,
    nav: false,
    autoplayTimeout: 0,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <>
      <div className="container counselling-hero">
        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="cons-hero-title">{parseHtml(data?.searchSection?.title)}</div>
            <div className="cons-para">{parseHtml(data?.searchSection?.subTitle)}</div>
            {console.log(userDetails)}
            {userDetails ? null : (
              <Link to="/login" className="btn btn-sach bg-sach-dark">
                <span>{data?.searchSection?.cta?.text}</span>
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g id="Property 1=arrow-right">
                    <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z"></path>
                  </g>
                </svg>
              </Link>
            )}
          </div>
          <div className="col-md-6 col-lg-6">
            <img src={require("../../../static/user/img/counselling/counselling-hero-bg.png").default} alt="counselling-hero-bg" className="img-fluid mt-5 mt-md-0" />
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="match-therapist">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <div className="m-therapist-ttl">{parseHtml(data?.therapistSection?.title)}</div>
              <div className="m-therapist-para">{parseHtml(data?.therapistSection?.content)}</div>
              <ul className="sach-inline-pills">
                {data?.therapistSection?.therapists &&
                  Object.values(data?.therapistSection?.therapists).map((therapist) => (
                    <li>
                      <span className={`custom-sach-pills ${therapist === "Chronic illness" ? "active" : ""}`}>{therapist}</span>
                    </li>
                  ))}
              </ul>

              {/* <a
                href
                onClick={() => {
                  setIsModalOpen(true);
                }}
                className="btn btn-sm btn-sach bg-sach"
              >
                <span>{data?.therapistSection?.cta?.text}</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 py-md-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 d-flex flex-column justify-content-center">
            <div className="OnOff-Counselling-ttl">
              <span>{data?.counsellingSection?.types?.onlineCounselling?.title} </span>
              <span className="text-sach">{data?.counsellingSection?.types?.onlineCounselling?.subTitle}</span>
            </div>
            <div className="OnOff-Counselling-para">{data?.counsellingSection?.types?.onlineCounselling?.content}</div>
          </div>
          <div className="col-md-6 col-lg-6 text-center">
            <img src={require("../../../static/user/img/counselling/online-counselling.png").default} alt="Online Counselling" className="img-fluid mt-4 mt-md-0" loading="lazy" />
          </div>
        </div>
      </div>

      <div className="container my-5 py-md-5">
        <div className="row">
          <div className="col-md-6 col-lg-6 text-center">
            <img src={require("../../../static/user/img/counselling/offline-counselling.png").default} alt="Online Counselling" className="img-fluid mt-4 mt-md-0" loading="lazy" />
          </div>
          <div className="col-md-6 col-lg-6 d-flex flex-column justify-content-center">
            <div className="OnOff-Counselling-ttl mt-5 mt-md-0">
              <span className="text-sach">{data?.counsellingSection?.types?.offlineCounselling?.title} </span>
              <span>{data?.counsellingSection?.types?.offlineCounselling?.subTitle} </span>
            </div>
            <div className="OnOff-Counselling-para">{data?.counsellingSection?.types?.offlineCounselling?.content}</div>
          </div>
        </div>
      </div>

      <section className="howDoesWrks">
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="sach-title">
                <h1 className="fs-40 f-700 mb-2">{parseHtml(data?.workSection?.title)}</h1>
                <p className="f-400"> {parseHtml(data?.workSection?.content)} </p>
              </div>
            </div>

            <div className="col-md-12">
              <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="sach-nav-tabs">
                <Tab eventKey="sach-tab-1" title={data?.counsellingSection?.types?.onlineCounselling?.title}>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <img src={require("../../../static/user/img/counselling/for-online-counselling.png").default} alt="for-online-counselling" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                      <div className="howDoesWrkCnt">
                        <div className="hdwc-ttl">{parseHtml(data?.workSection?.types?.online?.title)}</div>
                        <ul>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/check-o.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.online?.content?.quickAssessment)}
                          </li>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/list-2.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.online?.content?.bestTherapist)}
                          </li>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/attendees.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.online?.content?.communicate)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="sach-tab-2" title={data?.counsellingSection?.types?.offlineCounselling?.title}>
                  <div className="row">
                    <div className="col-md-6 text-center">
                      <img src={require("../../../static/user/img/counselling/for-offline-counselling.png").default} alt="for-offline-counselling" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                      <div className="howDoesWrkCnt">
                        <div className="hdwc-ttl">{parseHtml(data?.workSection?.types?.offline?.title)}</div>
                        <ul>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/check-o.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.offline?.content?.quickAssessment)}
                          </li>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/list-2.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.offline?.content?.nearestCenter)}
                          </li>
                          <li>
                            <i>
                              <img src={require("../../../static/user/img/icons/attendees.svg").default} alt="icon" />
                            </i>
                            {parseHtml(data?.workSection?.types?.offline?.content?.bookAppointment)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5 py-md-5">
        <div className="row mb-5">
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <div className="meetOurNetwork">
                  <h4>{parseHtml(data?.networkSection?.title)}</h4>
                  <b>{parseHtml(data?.networkSection?.subTitle)}</b>
                  <ul>{data?.networkSection?.networks && Object.values(data?.networkSection?.networks).map((network) => <li>{network}</li>)}</ul>
                  <a href="javascript:void(0);" className="btn btn-sach bg-sach-dark opacity-0">
                    <span>Meet Experts</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <g id="Property 1=arrow-right">
                        <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z"></path>
                      </g>
                    </svg>
                  </a>

                  <div className="counselorCarousel-wrp">
                    <OwlCarousel className="counselorCarousel owl-theme custom-owl-nav-1" {...counselorCarousel}>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          {/* <div className="consName">
                            <b>Teena Mathur</b>
                            <span>
                              <i>Health Consultant</i>
                            </span>
                          </div> */}
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="counselorCarouselItem">
                          <img src={require("../../../static/user/img/counselling/counselor-1.png").default} alt="counselor" />
                        </div>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="newsletter event-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-1">
              <div className="sach-title">
                <h1 className="f-700 fs-40 text-white text-md-start text-center">{parseHtml(data?.newsLetteeSection?.title)}</h1>
                <p className="text-white text-md-start text-center">{parseHtml(data?.newsLetteeSection?.subTitle)}</p>
              </div>
              <div className="row g-3 align-items-center my-md-4 my-2">
                <div className="col-lg-7">
                  <input type="text" id="newsletterEmail" className="form-control p-3 pe-5 transpInput" placeholder={data?.newsLetteeSection?.placeholderText} />
                </div>
                <div className="col-lg-4 col-sm-12 text-md-start text-center">
                  <span className="btn btn-sach bg-sach w-100 justify-content-center" data-aos-delay="400">
                    <span>{data?.newsLetteeSection?.cta?.text}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Modal title="Thank you!" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>We'll notify you on this soon.</p>
      </Modal> */}
      <Modal show={isModalOpen} centered className="sach-modal" backdrop="static" keyboard={false}>
        <div className="px-md-5 text-center mt-5">
          <img src={require("../../../static/user/img/icons/success-tick.svg").default} alt="Successfully" />
          <div className="modal-heading text-center my-4">{parseHtml(data?.newsLetteeSection?.successMessage)}</div>

          <div className="my-3 text-center">
            <a
              href
              onClick={() => {
                setIsModalOpen(false);
              }}
              className="btn-sach bg-sach-dark justify-content-center w-100"
            >
              <span className="py-1">{data?.newsLetteeSection?.successCta?.text}</span>
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    counselingData: state?.CommonReducer?.counselingData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCounselingData: () => dispatch(getCounselingData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Counseling);
