import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFoundationData } from "../../../redux/action/common-action";
import Philosophy from "../../../static/user/img/about/philosophy.png";
import Presence from "../../../static/user/img/about/presence.png";
import { parseHtml } from "../../../utils/util";

const FoundationInner = ({ getFoundationData, foundationData }) => {
  const style1 = {
    boxShadow: "0 0 -0.75rem 0 var(--rgba-primary-2)",
    whiteSpace: "nowrap",
  };
  useEffect(() => {
    getFoundationData();
  }, []);
  const [details, setDetails] = useState();

  const topSection = foundationData && foundationData.inner && foundationData.inner.topSection;
  const categorySection = foundationData && foundationData.inner && foundationData.inner.categorySection;
  const categoryDetails = foundationData && foundationData.inner && foundationData.inner.categoryDetails;

  const handleClickEvent = (id) => {
    const data = categoryDetails.filter((f) => f.key == id);
    setDetails(data);
  };
  return (
    <>
      <section>
        <div className="container">
          <div className="sach-foundation-inner">
            <div className="row">
              <div className="col-md-11 mx-auto">
                <div className="row">
                  <div className="col-md-9 text-md-start text-center">
                    <div className="foundation-title">
                      <h1>{topSection && parseHtml(topSection.title)}</h1>
                      <p>{topSection && parseHtml(topSection.desc)}</p>
                    </div>
                  </div>
                  <div className="col-md-3 position-relative">
                    <div className="sach-foundation-heroImg">
                      <div className="heroImg-inner">
                        <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/DrSubhashChandra.png" alt="Dr. Subhash Chandra" className="img-fluid" />
                        <div className="heroImg-inner-details">
                          <span>{topSection && parseHtml(topSection.subTitle)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <div className="d-flex align-items-start sach-nav-tabs">
                <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {categorySection &&
                    categorySection.map((item, index) => (
                      <button className={`nav-link ${item && item.key == 1 ? "active" : ""}`} id={`v-pills-${item && item.key}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-${item && item.key}`} type="button" role="tab" aria-controls="v-pills-home" aria-selected="true" style={style1} onClick={(e) => handleClickEvent(item && item.key)}>
                        {item && item.title}
                      </button>
                    ))}
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-ourStory" role="tabpanel" aria-labelledby="v-pills-ourStory-tab">
                    {details ? (
                      <>
                        <h4>{details && details[0] && details[0].title}</h4>
                        {details && details[0] && details[0].image_url && <img src={`${details[0].image_url}`} alt="Our Philosophy" className="img-fluid w-100" />}
                        {details && details[0] && parseHtml(details[0].desc)}
                      </>
                    ) : (
                      <>
                        <h4>{categoryDetails && categoryDetails[0] && categoryDetails[0].title}</h4>
                        {categoryDetails && categoryDetails[0] && categoryDetails[0].image_url && <img src={categoryDetails[0].image_url} alt="Our Philosophy" className="img-fluid w-100" />}
                        {categoryDetails && categoryDetails[0] && parseHtml(categoryDetails[0].desc)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  const { foundationData } = CommonReducer;
  return {
    foundationData: CommonReducer.foundationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFoundationData: () => dispatch(getFoundationData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoundationInner);
