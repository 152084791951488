import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSectoralData } from '../../../redux/action/common-action'
import { parseHtml } from "../../../utils/util";
import { connect } from "react-redux";
function Agriculture({ getSectoralData, sectoralData }) {
  const [topSection, setTopSection] = useState({});
  const [agriculturalSection, setAgriculturalSection] = useState({});
  const [developmentSection, setDevelopmentSection] = useState({});
  useEffect(() => {
    // 👇️ scroll to top on page load
    getSectoralData();
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (sectoralData?.agricilture?.topSection) {
      setTopSection(sectoralData?.agricilture?.topSection);
      setDevelopmentSection(sectoralData?.agricilture?.developmentSection);
      setAgriculturalSection(sectoralData?.agricilture?.agriculturalSection);
    }

  }, [sectoralData])
  return (
    <>
      <section className="grant-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Link to="/" className="grant-back-btn">
                <i className="bi bi-chevron-left"></i>  {topSection?.ctaText || 'Back'}
              </Link>
              <p className="h6">{topSection?.title}</p>
              <p className="h1">{topSection?.subTitle}</p>
              <p className="para w-75">
                {topSection?.desc}
              </p>
              <div className="img-container">
                <img
                  src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/integratedRuralDev.png"
                  alt="Hero Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-5">
        <div className="row early-edu-description">
          <div className="col-md-12">
            <div className="h1">
              <span>{developmentSection?.title} </span>
              <span className="text-sach">{developmentSection?.subTitle}</span>
            </div>
            <div className="para">
              {parseHtml(developmentSection?.desc)}
            </div>
            <img
              src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/villageDev.png"
              className="img-fluid w-100"
              alt="img"
            />
            <div className="para">
              {parseHtml(developmentSection?.desc1)}
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <div className="row early-edu-description">
          <div className="col-md-12">
            <div className="h1">
              <span>{agriculturalSection?.title} </span>
              <span className="text-sach">{agriculturalSection?.subTitle}</span>
            </div>
            <div className="para">
              {parseHtml(agriculturalSection?.desc)}
            </div>
            <img
              src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/SustainableAgriDev.png"
              className="img-fluid w-100"
              alt="img"
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    sectoralData: state?.CommonReducer?.sectoralData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectoralData: () => dispatch(getSectoralData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Agriculture);

