export const AdminRoutes = {
  DASHBOARD: "/admin/dashboard",
  USER_MANAGEMENT: "/admin/user-management",
  EVENT_MANAGEMENT: "/admin/event-management",
  USER_VIEW_DETAIL: "/admin/user-view-detail",
  CATEGORY_MANAGEMENT: "/admin/category-management",
  BLOG_MANAGEMENT: "/admin/blog-management",
  WRITE_BLOG: "/admin/write-blog",
  BLOG_DETAIL: "/admin/blog-detail",
  TESTIMONIALS: "/admin/testimonials",
  USER_ROLE_MANAGEMENT: "/admin/user-role-management",
  SETTINGS: "/admin/settings",
};

export const UserRoutes = {
  USER_EVENT: "/user/events",
  USER_BLOGS: "/user/blogs",
  DASHBOARD: "/user/user",
  ABOUT: "/user/about",
  GRANT: "/user/grant",
  FOUNDATION: "/user/foundation",
  FOUNDATION_INNER: "/user/foundation-inner",
  SECTORAL_CHILD_EDUCATION: "/user/child_education",
  SECTORAL_SACH_SCHOLARSHIP: "/user/sach_scholarship",
  SECTORAL_SACH_IMPACT: "/user/sach_impact",
  SECTORAL_SACH_AGRICULTURE: "/user/sach_agriculture",
  SECTORAL_SACH_SHOWS: "/user/sach_shows",
  SECTORAL_SACH_ESSELERATOR: "/user/sach_esselerator",
  SECTORAL_SACH_SARTHI: "/user/sach_sarthi",
  FAQ: "/user/faq",
  PRIVACY_POLICY: "/user/privacy_policy",
  COOKIE_POLICY: "/user/cookie_policy",
  TNC: "/user/T&C",
  CONTACT_US: "/user/contact_us",
  ORGANIC_FARMING: "/user/organic_farming",
  USER_VIJAY_SCHOLARSHIP: "/user/vijay_scholarship",
  USER_EVENT_SUMMARY: "/user/events-summary",
  USER_EVENT_DETAILS: "/user/events-details",
  USER_BLOG_SUMMARY: "/user/blogs-summary",
  CATEGORY_DETAIL_PAGE: "/user/category-details",
  CATEGORY_HOME_PAGE: "/user/categories",
  CATEGORY_VIDEO_PAGE: "/user/category-video",
  MY_ACCOUNT: "/user/account",
  WRITE_BLOG: "/user/write-blog",
  BLOG_DETAIL: "/user/blog-detail",
  COUNSELING: "/user/counseling",
  USER_CONTRIBUTE: "/user/contribute",
  USER_TESTIMONIALS: "/user/testimonials",
  USER_THOUGHT_OFTHE_DAY: "/user/thoughtOftheDay",
};
