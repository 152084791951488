import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { scholarImpactFocusData, scholarEligibilityData } from "./metaData";
import { connect } from "react-redux";
import { getSectoralData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";
function SachImpact({ getSectoralData, sectoralData }) {
  const [topSection, setTopSection] = useState({});
  const [areaSection, setAreaSection] = useState({});
  const [focusData, setFocusData] = useState([]);
  const [eligibilitySection, setEligibilitySection] = useState({});
  const [mentorSection, setMentorSection] = useState({});
  useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
    getSectoralData();
  }, []);
  const style = {
    boxShadow: "0 0 -0.75rem 0 var(--rgba-primary-2)",
  };
  useEffect(() => {
    if (sectoralData?.impact?.topSection) {
      setTopSection(sectoralData?.impact?.topSection);
      setAreaSection(sectoralData?.impact?.areaSection);
      setFocusData(sectoralData?.impact?.areaSection?.areas);
      setEligibilitySection(sectoralData?.impact?.eligibilitySection);
      setMentorSection(sectoralData?.impact?.mentorSection);
    }
  }, [sectoralData]);

  const focusArea = focusData.map((item) => {
    return (
      <>
        <div className="col-lg-4 col-md-4">
          <div className="focus-area">
            <img src={item.imgUrl} alt="Focus Area Icon" />
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          </div>
        </div>
      </>
    );
  });
  const eligibilityCate = eligibilitySection?.category?.map((item1) => {
    return (
      <button className={`nav-link  ${item1.key == 1 ? "active" : ""}`} id={`v-pills-tab-${item1.key}`} data-bs-toggle="pill" data-bs-target={`#v-tab-${item1.key}`} type="button" role="tab" aria-controls={`v-tab-${item1.key}`} aria-selected="false" style={style}>
        {item1.type}
      </button>
    );
  });

  const eligibilitySubCate = eligibilitySection?.subCategory?.map((sub) => {
    return (
      <div className={`tab-pane fade  ${sub?.categoryId == 1 ? "show active" : ""}`} id={`v-tab-${sub?.categoryId}`} role="tabpanel" aria-labelledby={`v-pills-tab-${sub?.categoryId}`}>
        <div className="h2">{sub?.title}</div>
        <p>{parseHtml(sub?.desc)}</p>
      </div>
    );
  });

  return (
    <>
      <section className="grant-hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Link to="/" className="grant-back-btn">
                <i className="bi bi-chevron-left"></i> {topSection?.ctaText || "Back"}
              </Link>
              <p className="h6">{topSection?.title}</p>
              <p className="h1">
                {topSection?.subTitle} + <img src={require("../../../static/user/img/SectoralFocus/LetsEndorse.png").default} alt="LetsEndorse" />
              </p>
              <p className="para">{topSection?.desc}</p>
              <div className="img-container">
                <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/sachImpact.png" alt="Hero Image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="focus-area-section">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="focus-title">{areaSection?.title}</div>
            </div>
            {focusArea}
          </div>
        </div>
      </section>

      <section className="eligibility-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="eligibility-title">{eligibilitySection?.title}</div>
            </div>
            <div className="col-md-12">
              <div className="sach-nav-tabs grant-nav-tabs eligibility-nav-tabs">
                <div className="row">
                  <div className="col-md-3">
                    <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      {eligibilityCate}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content" id="v-pills-tabContent">
                      {eligibilitySubCate}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mentors-section">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-12">
              <div className="mentors-title">{mentorSection?.title}</div>
              <div className="mentors-para">{mentorSection?.subTitle}</div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src={require("../../../static/user/img/SectoralFocus/mentors/PunitGoenka.png").default} alt="Punit-Goenka" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[0]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[0]?.designation}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src={require("../../../static/user/img/SectoralFocus/mentors/AmitGoenka.png").default} alt="Amit-Goenka" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[1]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[1]?.designation}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mentor-wrp">
                <img src={require("../../../static/user/img/SectoralFocus/mentors/AshokGoel.png").default} alt="Ashok-Goel" />
                <div className="mentors-details">
                  <div className="d-flex">
                    <span>{mentorSection?.mentors?.[2]?.name}</span>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </div>
                  <p>{mentorSection?.mentors?.[2]?.designation}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    sectoralData: state?.CommonReducer?.sectoralData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectoralData: () => dispatch(getSectoralData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SachImpact);
