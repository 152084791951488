import React from "react";
import AdminModal from "../../user/components/common/AdminModal";

const SuccessfullModal = ({ handleCancel, forgotPassword }) => {
  return (
    <AdminModal onCancel={handleCancel} footer={null} closable={true}>
      <div className="px-md-5 text-center mt-5">
        <img src={require("../../static/user/img/icons/success-tick.svg").default} alt="Successfully" />
        <div className="modal-heading text-center my-4">
          <h1>{forgotPassword && forgotPassword.successMsg || "Successfully Sent!"}</h1>
          {/* <span>We have sent the credentials on your registered E-mail Id.</span> */}
        </div>
      </div>
    </AdminModal>
  );
};

export default SuccessfullModal;
