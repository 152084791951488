const initState = {
    privacyPolicyData: [],
    termsData: [],
    faqData: [],
    error: null

};
const PrivacyReducer = (state = initState, action) => {

    const data = action?.data;
    switch (action?.type) {
        case "PRIVACY_Data_REQUESTED":
            return {
                ...state,
            };
        case "PRIVACY_POLICY_DATA":
            return {
                ...state,
                privacyPolicyData: data,
            };
        case "TERMS_CONDITIONS_DATA":
            return {
                ...state,
                termsData: data,
            };
        case "FAQ_DATA":
            return {
                ...state,
                faqData: data,
            };
        default:
            return state;
    }
}

export default PrivacyReducer;