// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/antd.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!./customAntd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*** Ant design custom css ***/\nthead[class*=\"ant-table-thead\"] th {\n  background-color: #f6f4f2 !important;\n}\n\n.dropdown-item:focus,\n.dropdown-item:hover {\n  color: inherit !important;\n  background-color: transparent !important;\n}\n\n.dropdown-item img {\n  margin-right: 3px;\n}\n\n.closeBtn {\n  margin-left: 0 !important;\n}\n\n.blogTitle {\n  box-shadow: none !important;\n  outline: none;\n  border: none;\n}\n\n.user-blog-wrapper .mx-5 {\n  padding: 0 200px;\n}\n\n.btn-sach.disable {\n  opacity: 0.5;\n  cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAGA,8BAA8B;AAC9B;EACE,oCAAoC;AACtC;;AAEA;;EAEE,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["@import \"~antd/dist/antd.css\";\n@import url(\"./customAntd.css\");\n\n/*** Ant design custom css ***/\nthead[class*=\"ant-table-thead\"] th {\n  background-color: #f6f4f2 !important;\n}\n\n.dropdown-item:focus,\n.dropdown-item:hover {\n  color: inherit !important;\n  background-color: transparent !important;\n}\n\n.dropdown-item img {\n  margin-right: 3px;\n}\n\n.closeBtn {\n  margin-left: 0 !important;\n}\n\n.blogTitle {\n  box-shadow: none !important;\n  outline: none;\n  border: none;\n}\n\n.user-blog-wrapper .mx-5 {\n  padding: 0 200px;\n}\n\n.btn-sach.disable {\n  opacity: 0.5;\n  cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
