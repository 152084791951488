import axios from "axios";
import { getCommonApiHeader } from "../../../utils/util";
import { POST_SUBSCRIPTION_USER_URL } from "../../constants";
import { handleError, handleSuccess } from "../common";

export const userPostSubscriptionApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getSubscriptionRequest());
    axios
      .post(POST_SUBSCRIPTION_USER_URL, data, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch({
          type: "EMAIL_FAILD_DATA",
          data: response,
        });
        dispatch(handleSuccess({ ...response, message: "Request send successfully!" }));
      })
      .catch((err) => {
        dispatch({
          type: "EMAIL_FAILD_DATA",
          data: "400",
        });
        console.log(err);
        // dispatch(handleError({ message: "Request send failed!" }));
      });
  };
};

export const getSubscriptionRequest = (data) => {
  return {
    type: "SUBSCRIPTION_Data_REQUESTED",
  };
};

export const getSubscriptionDataRespond = (data) => {
  return {
    type: "USER_SUBSCRIPTION_DATA",
    data: data,
  };
};
