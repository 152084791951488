import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { childEducationData, scholarshipData } from "./metaData";
import { Modal } from "antd";
import { connect } from "react-redux";
import { getSectoralData } from "../../../redux/action/common-action";
import { parseHtml } from "../../../utils/util";
import { getCategoryAllData } from "../../../redux/action/user/category";

function SachScholoarship({ getSectoralData, sectoralData, getCategoryAllData, sectionData }) {
  const [topSection, setTopSectionData] = useState({});
  const [vijaySection, setVijaySection] = useState({});
  const [sachVijaySection, setSachVijaySection] = useState({});
  const [scholarshipCoverSection, setScholarshipCoverSection] = useState({});
  const [watchSection, setWatchSection] = useState({});
  const [faqSection, setFaqSection] = useState({});
  useEffect(() => {
    getSectoralData();
    getCategoryAllData();
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (sectoralData?.scholarship) {
      setTopSectionData(sectoralData?.scholarship?.topSection);
      setVijaySection(sectoralData?.scholarship?.vijaySection);
      setWatchSection(sectoralData?.scholarship?.watchSection);
      setSachVijaySection(sectoralData?.scholarship?.sachVijaySection);
      setScholarshipCoverSection(sectoralData?.scholarship?.scholarshipCoverSection);
      setFaqSection(sectoralData?.scholarship?.faqSection);
    }
  });

  const topVideos = sectionData && sectionData.scroll;
  const [playing, setPlaying] = useState(true);
  const pauseVideo = () => {
    var iframes = document.querySelectorAll("iframe");
    for (let i = 0; i < iframes.length; i++) {
      if (iframes[i] !== null) {
        var temp = iframes[i].src;
        iframes[i].src = temp;
      }
    }
  };
  const handleCancel = () => {
    setPlaying(false);
    pauseVideo();
    setTimeout(() => setPlayerModal(false), 500);
    setPlayBtn(1);
    setVideoIcon("");
    setvideoChannelKey("");
  };
  const [isLoader, setIsLoader] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const [playBtn, setPlayBtn] = useState(1);
  const [videoIcon, setVideoIcon] = useState("");
  const [videoChannelKey, setvideoChannelKey] = useState("");
  const handleVideo = (record) => {
    setVideoIcon(record.icon);
    setvideoChannelKey(record.channelKey);
    setPlayerModal(true);
    setIsLoader(true);
    window.addEventListener("dyntubeReady", () => {
      setIsLoader(false);
      var player = dyntube.getPlayer(record.channelKey);
      player.play();
    });
  };

  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = '!function (e, t, i) { if (void 0 === e._dyntube_v1_init) { e._dyntube_v1_init = !0; var a = t.createElement("script"); a.type = "text/javascript", a.async = !0, a.src = "https://embed.dyntube.com/v1.0/dyntube.js", t.getElementsByTagName("head")[0].appendChild(a) } }(window, document);';
    document.body.appendChild(s);
  }, [location]);

  const [collapse, setCollapse] = useState(true);
  const style = {
    backgroundColor: "var(--theme-orange)",
  };

  return (
    <>
      <section className="scholarship-hero">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <Link to="/" className="SF-back-btn backbtn">
                <i className="bi bi-chevron-left"></i> {topSection?.ctaText || "Back"}
              </Link>
              <p className="h6">{topSection?.title}</p>

              <h1 className="title">{parseHtml(topSection?.subTitle)}</h1>
              <p className="para"> {parseHtml(topSection?.desc)}</p>

              <span className="download-label">{topSection?.download}:</span>
              <a href="https://sach-public-file.s3.ap-south-1.amazonaws.com/SACH-Vijaya-Application-form-2020-form.pdf" target="_blank" className="btn btn-sach" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{topSection?.form}</span>
              </a>
              <a href="https://sach-public-file.s3.ap-south-1.amazonaws.com/SACH-Vijaya-Recommendation-form-2020-form.pdf" target="_blank" className="btn btn-sach ms-3" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{topSection?.recommendationForm}</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="container my-5">
        <div className="row early-edu-description">
          <div className="col-md-6">
            <div className="sach-vijaya-log">
              <img src={require("../../../static/user/img/sach-icon.svg").default} className="p-3 ps-0" alt="Sach" />
              <img src={require("../../../static/user/img/VijayaScholarship.jpg").default} alt="Sach" />
            </div>
            <div className="h2">{vijaySection?.title}</div>
            <div className="para my-2">{parseHtml(vijaySection?.desc)}</div>
            {vijaySection?.vijayData?.map((dt) => (
              <>
                <div className="h2">{dt?.title}</div>
                <div className="para my-2">{dt?.desc}</div>
                {parseHtml(dt?.subDesc)}
              </>
            ))}
          </div>
          <div className="col-md-5 offset-md-1 align-self-end">
            <div className="desc-img">
              <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/desc-1.png" alt="Children" />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="SF-Title-SM-2 text-sach">{watchSection?.title}</div>
            <div className="SF-Title-2">{parseHtml(watchSection?.subTitle)}</div>
            <p className="SF-Title-SubTitle-2">{parseHtml(watchSection?.desc)}</p>
            {console.log(topVideos)}
            <div className="SF-img-cont">
              {playBtn ? <img src={require("../../../static/user/img/icons/play-round-text.svg").default} alt="Play Button" className="ply-btn" onClick={() => handleVideo(topVideos && topVideos.cards && topVideos.cards[1])} /> : null}
              <img src={require("../../../static/user/img/SectoralFocus/VisualFeaturing.png").default} alt="Mr. Subhash Chandra" />
            </div>

            {playerModal && (
              <Modal centered closable={true} onCancel={handleCancel} visible={true} footer={null} width={1100} className="home-hero-video">
                {isLoader && (
                  <div className="row mt-lg-5 mt-3">
                    <div className="col-12 text-center position-relative">
                      <video className="w-100" poster={videoIcon}></video>
                    </div>
                  </div>
                )}
                <div data-dyntube-key={videoChannelKey}></div>
              </Modal>
            )}
          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="SF-Title-2">
              <span className="text-sach">{sachVijaySection?.title}</span>
              {parseHtml(sachVijaySection?.subTitle)}
            </div>
            <div className="SF-Title-3 my-4">{parseHtml(sachVijaySection?.desc)}</div>
          </div>
        </div>
        <div className="row early-edu-description">
          <div className="col-md-6">
            <div className="h3">{scholarshipCoverSection?.title}</div>
            {parseHtml(scholarshipCoverSection?.desc)}
            <Link to="/user/vijay_scholarship" className="btn btn-sach" style={style}>
              <span>{parseHtml(scholarshipCoverSection?.linkText)}</span>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g id="Property 1=arrow-right">
                  <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z"></path>
                </g>
              </svg>
            </Link>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="desc-img">
              <img src="https://sach-public-file.s3.ap-south-1.amazonaws.com/Sactoral+Focus/desc-4.png" alt="Children" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="dowld-sec">
              <span>{topSection?.download}:</span>
              <a href="https://sach-public-file.s3.ap-south-1.amazonaws.com/SACH-Vijaya-Application-form-2020-form.pdf" target="_blank" className="btn btn-sach" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{topSection?.form}</span>
              </a>
              <a href="https://sach-public-file.s3.ap-south-1.amazonaws.com/SACH-Vijaya-Recommendation-form-2020-form.pdf" target="_blank" download className="btn btn-sach ms-3" style={style}>
                <svg className="me-2" width="20" height="20" viewBox="0 0 21 19" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.25 0.128906C10.6642 0.128906 11 0.464693 11 0.878906V12.9199C11 13.3341 10.6642 13.6699 10.25 13.6699C9.83579 13.6699 9.5 13.3341 9.5 12.9199V0.878906C9.5 0.464693 9.83579 0.128906 10.25 0.128906Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.6953 9.46077C13.9887 9.75306 13.9897 10.2279 13.6974 10.5214L10.7814 13.4494C10.6407 13.5907 10.4495 13.6702 10.25 13.6702C10.0506 13.6702 9.85934 13.5907 9.7186 13.4494L6.8026 10.5214C6.51031 10.2279 6.51128 9.75306 6.80478 9.46077C7.09827 9.16848 7.57314 9.16945 7.86543 9.46295L10.25 11.8573L12.6346 9.46295C12.9269 9.16945 13.4018 9.16848 13.6953 9.46077Z" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.75 9.29808C18.7501 8.91271 18.6743 8.5311 18.5269 8.17504C18.3795 7.81898 18.1634 7.49544 17.8909 7.22291C17.6185 6.95038 17.295 6.73419 16.939 6.58669C16.583 6.4392 16.2014 6.36328 15.816 6.36328H14.883C14.4688 6.36328 14.133 6.02749 14.133 5.61328C14.133 5.19907 14.4688 4.86328 14.883 4.86328H15.816C16.3984 4.86328 16.9751 4.97801 17.5131 5.20091C18.0511 5.42382 18.54 5.75053 18.9517 6.1624C19.3635 6.57426 19.6901 7.0632 19.9128 7.6013C20.1356 8.13933 20.2501 8.71597 20.25 9.29828C20.25 9.29835 20.25 9.29821 20.25 9.29828V14.1823C20.25 15.3559 19.7838 16.4814 18.9539 17.3112C18.1241 18.1411 16.9986 18.6073 15.825 18.6073H4.685C3.50877 18.6073 2.3807 18.14 1.54898 17.3083C0.717257 16.4766 0.25 15.3485 0.25 14.1723V9.28728C0.250319 8.11391 0.716665 6.9885 1.54648 6.15891C2.37629 5.32932 3.50163 4.86328 4.675 4.86328H5.617C6.03121 4.86328 6.367 5.19907 6.367 5.61328C6.367 6.02749 6.03121 6.36328 5.617 6.36328H4.675C3.89938 6.36328 3.15552 6.67134 2.60699 7.21971C2.05852 7.76804 1.75027 8.51173 1.75 9.28728C1.75 9.28735 1.75 9.28721 1.75 9.28728V14.1723C1.75 14.9507 2.05922 15.6972 2.60964 16.2476C3.16006 16.7981 3.90659 17.1073 4.685 17.1073H15.825C16.6008 17.1073 17.3447 16.7991 17.8933 16.2506C18.4418 15.702 18.75 14.958 18.75 14.1823V9.29808Z"
                  />
                </svg>
                <span>{topSection?.recommendationForm}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-md-5 my-0 py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="SF-Title-SM-2 text-sach">{faqSection?.title}</div>
            <div className="SF-Title-2">{parseHtml(faqSection?.subTitle)}</div>
            <p className="SF-Para"> {parseHtml(faqSection?.desc)}</p>
            <div className="sach-accordion-2 accordion mt-5" id="FAQ_Accordion">
              {faqSection?.faqTabs?.map((dt, index) => (
                <>
                  <div className="accordion-item" key={index}>
                    <button className={`accordion-button ${index != 0 ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${index}`} aria-expanded={`${index == 0 ? "true" : "false"}`} aria-controls={`collapse_${index}`}>
                      {dt && parseHtml(dt.question)}
                    </button>
                    <div id={`collapse_${index}`} className={`accordion-collapse ${index == 0 ? " show" : "collapse"}`} data-bs-parent="#FAQ_Accordion">
                      <div className="accor_wrp">
                        <div>{dt && parseHtml(dt.answer)}</div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { UserCategoryReducer } = state;
  const { allCategoryData = {}, start = 0 } = UserCategoryReducer;

  return {
    ...state,
    ...allCategoryData,
    sectoralData: state?.CommonReducer?.sectoralData || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSectoralData: () => dispatch(getSectoralData()),
    getCategoryAllData: (data) => dispatch(getCategoryAllData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SachScholoarship);
