import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { checkValidUsername, getUserId, goToLoginPage, startTimer, checkValidPassword, checkValidPhone, checkValidFullName, parseHtml } from "../../utils/util";

const SignUpPage = (props) => {
  const { generateOTPCall, verifyOTPCall, signUpData, submitBasicInfo, resetSignUpData, showPopup, verifyOTPFailed, getSignUpDataAPI, signUpJsonData } = props;
  let navigate = useNavigate();
  let timer;
  const { pathname } = useLocation();

  const [currStep, setCurrStep] = useState(1);
  const [formValues, setFormValues] = useState([]);
  const [isFormValid, setValidForm] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [disableResendOtpBtn, setDisableResendOtpBtn] = useState(true);
  const [showError, setShowError] = useState("");
  const [fromEvent, setFromEvent] = useState(-1);
  useEffect(() => {
    resetSignUpData();
    getSignUpDataAPI();
    setFromEvent(window.location.href.indexOf("fromEvent="));
  }, []);

  const [value, setValue] = useState();

  const countryValChange = () => {
    document.querySelector("#mobileNumber").focus();
    setValue();
  };

  useEffect(() => {
    if (currStep === 1 && signUpData.isLoading === false && signUpData.isError === false) {
      setCurrStep(2);
      setShowError(signUpData?.data?.data?.message || "Something went wrong, please try again");
    } else if (currStep === 1 && signUpData.isLoading === false && signUpData.isError === true) {
      setShowError(signUpData?.data?.data?.message || "Something went wrong, please try again");
    }
  }, [signUpData]);

  useEffect(() => {
    if (currStep === 2) {
      timer = startTimer(10, () => setDisableResendOtpBtn(false));
    } else {
      clearInterval(timer);
    }
  }, [currStep]);

  const carouselSection = signUpJsonData && signUpJsonData.signup && signUpJsonData.signup.carouselSection;
  const step1 = signUpJsonData && signUpJsonData.signup && signUpJsonData.signup.step1;
  const step2 = signUpJsonData && signUpJsonData.signup && signUpJsonData.signup.step2;
  const step3 = signUpJsonData && signUpJsonData.signup && signUpJsonData.signup.step3;
  const step4 = signUpJsonData && signUpJsonData.signup && signUpJsonData.signup.step4;

  const onChangeSignUpform = (event) => {
    if (showError) {
      setShowError("");
    }
    const copyForm = Object.assign({}, formValues);
    copyForm[event.target.id] = event.target.value;
    if (event.target.id === "fullName" && event.target.value !== "" && checkValidFullName(event.target.value)) {
      copyForm["isValidName"] = true;
    } else if (event.target.id === "fullName" && event.target.value !== "" && !checkValidFullName(event.target.value)) {
      copyForm["isValidName"] = false;
    }

    if (event.target.id === "email" && event.target.value !== "" && checkValidUsername(event.target.value)) {
      copyForm["isValidEmail"] = true;
    } else if (event.target.id === "email" && event.target.value !== "" && !checkValidUsername(event.target.value)) {
      copyForm["isValidEmail"] = false;
    }

    if (event.target.id === "mobileNumber" && event.target.value !== "" && checkValidPhone(event.target.value)) {
      copyForm["isValidPhone"] = true;
    } else if (event.target.id === "mobileNumber" && event.target.value !== "" && !checkValidPhone(event.target.value)) {
      copyForm["isValidPhone"] = false;
    }

    if (event.target.id === "password" && event.target.value !== "" && checkValidPassword(event.target.value)) {
      copyForm["isValidPassword"] = true;
    } else if (event.target.id === "password" && event.target.value !== "" && !checkValidPassword(event.target.value)) {
      copyForm["isValidPassword"] = false;
    }
    if (event.target.id === "OTPChar1" || event.target.id === "OTPChar2" || event.target.id === "OTPChar3" || event.target.id === "OTPChar4") {
      const fieldIndex = event.target.id[event.target.id.length - 1];
      let fieldIntIndex = parseInt(fieldIndex, 10);
      const nextfield = document.querySelector(`input[id=OTPChar${fieldIntIndex + 1}]`);
      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
    if (copyForm["isValidName"] && copyForm["isValidEmail"] && copyForm["isValidPassword"] && copyForm["isValidPhone"]) {
      setValidForm(true);
    } else {
      //  setValidForm(false);
    }
    setFormValues(copyForm);
  };
  const ageGroupMapping = {
    "AgeGroup-1": "12-29",
    "AgeGroup-2": "30-39",
    "AgeGroup-3": "40-49",
    "AgeGroup-4": "50+",
  };
  const onChangeSelection = (event) => {
    const copyForm = Object.assign({}, formValues);
    if (event.target.name === "AgeGroup") {
      copyForm[event.target.name] = ageGroupMapping[event.target.id];
    } else {
      copyForm[event.target.name] = event.target.id;
    }
    setFormValues(copyForm);
  };
  const onChangeAspirationSelection = (event) => {
    const copyForm = Object.assign({}, formValues);
    if (copyForm[event.target.name]) {
      if (copyForm[event.target.name].indexOf(event.target.id) >= 0) {
        copyForm[event.target.name].splice(copyForm[event.target.name].indexOf(event.target.id), 1);
      } else {
        if (["Goals"].includes(event.target.name)) {
          if (copyForm[event.target.name].length === 3) {
            setShowError("Choose up to 3 goals");
            event.target.value = "";
            event.target.checked = false;
          } else {
            copyForm[event.target.name].push(event.target.id);
          }
        } else {
          copyForm[event.target.name].push(event.target.id);
        }
      }
    } else {
      copyForm[event.target.name] = [event.target.id];
    }
    setFormValues(copyForm);
  };
  const togglePasswordShown = () => {
    setShowPass(!showPass);
  };
  const setErrorMsg = () => {
    if (!formValues["fullName"] || !formValues["isValidName"]) {
      setShowError((step1 && step1.formField && step1.formField.validName) || "Please Enter Valid Name, Only alphabets are allowed");
    } else if (!formValues["email"] || !formValues["isValidEmail"]) {
      setShowError((step1 && step1.formField && step1.formField.validEmail) || "Please Enter Valid Email Address");
    } else if (!formValues["mobileNumber"] || !formValues["isValidPhone"]) {
      setShowError((step1 && step1.formField && step1.formField.validPhone) || "Please Enter Valid Phone Number");
    } else if (!formValues["password"] || !formValues["isValidPaswword"]) {
      setShowError((step1 && step1.formField && step1.formField.validPassword) || "Please Enter Strong Password");
    } else {
      return true;
    }
  };

  const onClickNext = (type) => {
    if (!isFormValid) {
      setErrorMsg();
      return;
    }
    if (type === "REQUEST_OTP") {
      generateOTPCall({
        crId: "crid-58634587385",
        userContact: `${formValues?.mobileNumber}`,
        userName: formValues?.fullName,
        userEmail: formValues?.email,
        password: formValues?.password,
      });
    } else {
      if (currStep == 2 && fromEvent != -1) {
        goToLoginPage(pathname, navigate);
        return;
      }
      setShowError("");
      setCurrStep(currStep + 1);
    }
  };

  const onClickResendOtp = () => {
    formValues.OTPChar1 = "";
    formValues.OTPChar2 = "";
    formValues.OTPChar3 = "";
    formValues.OTPChar4 = "";
    document.getElementById("OTPChar1").value = "";
    document.getElementById("OTPChar2").value = "";
    document.getElementById("OTPChar3").value = "";
    document.getElementById("OTPChar4").value = "";
    if (disableResendOtpBtn) return;
    timer = startTimer(10, () => setDisableResendOtpBtn(false));
    generateOTPCall({
      crId: "58634587385",
      userContact: `${formValues?.mobileNumber}`,
      userName: formValues?.fullName,
      userEmail: formValues?.email,
      password: formValues?.password,
    });
    setDisableResendOtpBtn(true);
  };

  const onClickVerifyOTP = () => {
    if (formValues?.OTPChar1 === undefined || formValues?.OTPChar2 === undefined || formValues?.OTPChar3 === undefined || formValues?.OTPChar4 === undefined) {
      return;
    }
    const data = {
      crId: "23432432423",
      userContact: `${formValues?.mobileNumber}`,
      userName: formValues?.fullName,
      userEmail: formValues?.email,
      password: formValues?.password,
      otp: formValues?.OTPChar1 + formValues?.OTPChar2 + formValues?.OTPChar3 + formValues?.OTPChar4,
    };
    verifyOTPCall(data);
  };

  const onSubmitSignUp = (type) => {
    if (type === "SKIP") {
      goToLoginPage(pathname, navigate);
    } else if (type === "NEXT") {
      const userId = getUserId();
      const data = {
        userId: userId,
        userName: formValues?.fullName,
        userEmail: formValues?.email,
        gender: formValues?.Gender,
        ageGroup: formValues?.AgeGroup,
        feeling: formValues?.Feeling,

        goals: formValues?.Goals,
        elevate: formValues?.elevate,
        Issue: formValues?.Issue,
        spendDuration: formValues?.spendDuration,
        availableTime: formValues?.Days,
      };
      submitBasicInfo(data, navigate, pathname);
      showPopup(null);
      // goToLoginPage(pathname, navigate);
    }
  };
  const inputNumberOnly = (evt) => {
    if (evt.keyCode !== 8 && evt.keyCode !== 189 && (evt.keyCode <= 47 || evt.keyCode >= 58) && (evt.keyCode <= 95 || evt.keyCode >= 106)) {
      evt.stopPropagation();
      evt.preventDefault();
      return false;
    }
  };
  const renderInitalForm = () => {
    return (
      <div className="col-md-5 col-lg-5 offset-lg-1 offset-md-1" id="step-1">
        <span className="stepNum">{step1 && step1.step}</span>
        <h4 className="title">{step1 && step1.title}</h4>
        <p className="para">{step1 && step1.subTitle}</p>
        <div className="row">
          <div className="col-12 sach-form">
            <label htmlFor="fullName" className="form-label">
              {step1 && step1.formField && step1.formField.fname}
              <small>*</small>
            </label>
            <input type="text" maxLength={50} max={50} className="form-control text-capitalize" id="fullName" onChange={(event) => onChangeSignUpform(event)} />
          </div>
          <div className="col-12 sach-form">
            <label htmlFor="email" className="form-label">
              {step1 && step1.formField && step1.formField.email}
              <small>*</small>
            </label>
            <input type="text" className="form-control" id="email" onChange={(event) => onChangeSignUpform(event)} />
          </div>
          <div className="col-12 sach-form">
            <label htmlFor="mobileNumber" className="form-label">
              {step1 && step1.formField && step1.formField.mobile}
              <small>*</small>
            </label>
            <input type="text" className="form-control" id="mobileNumber" maxLength={10} onKeyDown={inputNumberOnly} onChange={(event) => onChangeSignUpform(event)} />
          </div>
          <div className="col-12 sach-form">
            <label htmlFor="password" className="form-label">
              {step1 && step1.formField && step1.formField.password}
              <small>*</small>
            </label>
            {/* <CountryDropdown id="UNIQUE_ID" className="YOUR_CSS_CLASS" preferredCountries={["gb", "us"]} value="" handleChange={(e) => console.log(e.target.value)}></CountryDropdown> */}
            <div className="pass-wrapper">
              <input type={showPass ? "text" : "password"} className="form-control pe-5" id="password" onChange={(event) => onChangeSignUpform(event)} />
              {!showPass ? <i onClick={togglePasswordShown} className="bi bi-eye-slash"></i> : <i onClick={togglePasswordShown} className="bi bi-eye"></i>}
            </div>
          </div>
          <div className="col-md-12 password-inst">{step1 && step1.formField && parseHtml(step1.formField.passwordErrorContent)}</div>

          <div className="TnC">
            {step1 && step1.tnCText && parseHtml(step1.tnCText.title)}
            <Link to="/user/T&C" onClick={() => showPopup(null)}>
              <b> {step1 && step1.tnCText && parseHtml(step1.tnCText.subTitle)}</b>
            </Link>
            <b> & </b>
            <Link to="/user/privacy_policy" onClick={() => showPopup(null)}>
              {step1 && step1.tnCText && parseHtml(step1.tnCText.policy)}
            </Link>
          </div>
          <div className="col-12 sach-form">
            <a href="javascript:void(0);" className="btn btn-sach bg-sach-dark justify-content-center w-100 mt-1" onClick={() => onClickNext("REQUEST_OTP")}>
              {step1 && step1.resend}
            </a>
          </div>
          {showError && <div className="col-12 mt-1 error-msg">{showError}</div>}
          <p className="details">
            {step1 && step1.newAccount && parseHtml(step1.newAccount.subTitle)}
            <a href=" #" onClick={() => goToLoginPage(pathname, navigate)}>
              {step1 && step1.newAccount && parseHtml(step1.newAccount.title)}
            </a>
          </p>
        </div>
      </div>
    );
  };

  const renderVerifyAccount = () => {
    return (
      <div className="col-md-5 col-lg-5 offset-lg-1 offset-md-1 mt-md-5 pb-md-5 mt-0 pb-0" id="step-2">
        <span className="stepNum">{step2 && step2.step}</span>
        <h4 className="title">{step2 && step2.title}</h4>
        <p className="para">{step2 && step2.subTitle}</p>
        <div className="row">
          <div className="col-12 sach-form sach-form-OTP">
            <label className="form-label d-block">{step2 && step2.otpLable}</label>
            <div></div>
            <input type="text" className="form-control" maxLength="1" id="OTPChar1" onChange={(event) => onChangeSignUpform(event)} />
            <input type="text" className="form-control" maxLength="1" id="OTPChar2" onChange={(event) => onChangeSignUpform(event)} />
            <input type="text" className="form-control" maxLength="1" id="OTPChar3" onChange={(event) => onChangeSignUpform(event)} />
            <input type="text" className="form-control" maxLength="1" id="OTPChar4" onChange={(event) => onChangeSignUpform(event)} />
          </div>
          {!signUpData?.otpVerified && <div className="col-12 mt-1 error-msg">{signUpData?.data?.data?.message || "Please enter correct otp"}</div>}
          <div className="col-12 my-2 resendOTP">
            <a href=" #" className={disableResendOtpBtn ? "btn-disabled text-sach" : "text-sach"} onClick={() => onClickResendOtp()}>
              {step2 && step2.resendOtp}
            </a>
            <span>
              <img src={require("../../static/user/img/icons/clock.svg").default} alt="Clock" /> {step2 && step2.time && step2.time.title}
              <b id="timer-value" className="text-sach">
                40
              </b>{" "}
              {step2 && step2.time && step2.time.subTitle}
            </span>
          </div>
          <div className="col-12 sach-form">
            <a href=" #" className="btn btn-sach bg-sach-dark justify-content-center w-100" onClick={onClickVerifyOTP}>
              {step2 && step2.verifyOtp}
            </a>
          </div>

          <div className="col-12 sach-form text-center">
            <b
              className="text-sach"
              role="button"
              onClick={() => {
                setCurrStep(1);
                setFormValues([]);
              }}
            >
              {step2 && step2.changeNumber}
            </b>
          </div>
        </div>

        <div id="verified-popup" className="accountVerified">
          <div className="accountVerified-body">
            {/* <span className="modal-close" onClick={closeVerifyModal}>
              <i className="bi bi-x"></i>
            </span> */}
            <img src={require("../../static/user/img/icons/success-tick.svg").default} alt="Success" />
            <h4>{step2 && step2.accountVerified}</h4>
            <p>{step2 && step2.verifyMsg}</p>
            <a href=" #" className="btn btn-sach bg-sach-dark mt-3 px-5" onClick={() => onClickNext()}>
              {step2 && step2.next}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderBasicInfoForm = () => {
    return (
      <div className="col-md-6 col-lg-6 offset-lg-1 offset-md-1" id="step-3" styles="display: none;">
        <span className="stepNum">{step3 && step3.step}</span>
        <h4 className="title">{step3 && step3.title}</h4>
        <p className="para">{step3 && step3.subTitle}</p>
        <div className="row">
          <div className="col-12 sach-form">
            <div className="icon-group-title">{step3 && step3.gender && step3.gender[0].name}</div>
            <ul className="icon-group" onClick={(event) => onChangeSelection(event)}>
              {step3 &&
                step3.gender &&
                step3.gender.map((item, index) => (
                  <>
                    {item && item.imageUrl && (
                      <li key={index}>
                        <input type="radio" className="btn-check" name="Gender" id={item.name} autoComplete="off" />
                        <label className="btn btn-outline-primary" htmlFor={item.name}>
                          <img src={item.imageUrl} alt="Gender" />
                          {item.name}
                        </label>
                      </li>
                    )}
                  </>
                ))}
              {/* // <li>
              //   <input type="radio" className="btn-check" name="Gender" id="Female" autoComplete="off" />
              //   <label className="btn btn-outline-primary" htmlFor="Female">
              //     <img src={step3 && step3.gender && step3.female.image_url} alt="Female" />
              //     {step3 && step3.gender && step3.female.name}
              //   </label>
              // </li>
              // <li>
              //   <input type="radio" className="btn-check" name="Gender" id="Other" autoComplete="off" />
              //   <label className="btn btn-outline-primary" htmlFor="Other">
              //     <img src={step3 && step3.gender && step3.other.image_url} alt="Other" />
              //     {step3 && step3.gender && step3.other.name}
              //   </label>
              // </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form">
            <div className="icon-group-title">Select Age Group</div>
            <ul className="icon-group" onClick={(event) => onChangeSelection(event)}>
              <li>
                <input type="radio" className="btn-check" name="AgeGroup" id="AgeGroup-1" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="AgeGroup-1">
                  <img src={require("../../static/user/img/icons/signupIcons/12-29.svg").default} alt="AgeGroup-1" /> 12-29
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="AgeGroup" id="AgeGroup-2" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="AgeGroup-2">
                  <img src={require("../../static/user/img/icons/signupIcons/30-39.svg").default} alt="AgeGroup-2" />
                  30-39
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="AgeGroup" id="AgeGroup-3" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="AgeGroup-3">
                  <img src={require("../../static/user/img/icons/signupIcons/40-49.svg").default} alt="AgeGroup-3" />
                  40-49
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="AgeGroup" id="AgeGroup-4" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="AgeGroup-4">
                  <img src={require("../../static/user/img/icons/signupIcons/50+.svg").default} alt="AgeGroup-4" />
                  50+
                </label>
              </li>
            </ul>
          </div>
          <div className="col-12 sach-form">
            <div className="icon-group-title">How you're feeling now?</div>
            <ul className="icon-group" onClick={(event) => onChangeSelection(event)}>
              {step3 &&
                step3.feeling &&
                step3.feeling.map((item, index) => (
                  <li key={index}>
                    <input type="radio" className="btn-check" name="Feeling" id={item && item.text} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor={item && item.text}>
                      <img src={item && item.imageUrl} alt="happy" /> {item && item.text}
                    </label>
                  </li>
                ))}

              {/* <li>
                <input type="radio" className="btn-check" name="Feeling" id="sad" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="sad">
                  <img src={step3 && step3.sad && step3.sad.image_url} alt="sad" />
                  {step3 && step3.sad && step3.sad.text}
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="Feeling" id="angry" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="angry">
                  <img src={step3 && step3.Angry && step3.Angry.image_url} alt="angry" />
                  {step3 && step3.Angry && step3.Angry.text}
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="Feeling" id="tired" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="tired">
                  <img src={step3 && step3.Tired && step3.Tired.image_url} alt="tired" />
                  {step3 && step3.Tired && step3.Tired.text}
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="Feeling" id="noEmotion" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="noEmotion">
                  <img src={step3 && step3.none && step3.none.image_url} alt="noEmotion" />
                  {step3 && step3.none && step3.none.text}
                </label>
              </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form">
            <a href=" #" className="btn btn-sach btn-sach-linear me-3" onClick={() => onClickNext()}>
              {step3 && step3.skip}
            </a>
            <a href=" #" className="btn btn-sach bg-sach-dark px-4" onClick={() => onClickNext()}>
              {step3 && step3.Next}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderAspirationsForm = () => {
    return (
      <div className="col-md-10 col-lg-10 offset-lg-1 offset-md-1" id="step-4" styles="display: none;">
        <span className="stepNum">{step4 && step4.step}</span>
        <h4 className="title">{step4 && step4.title}</h4>
        <p className="para">{step4 && step4.subTitle}</p>
        <div className="row signUp-fixedHeight">
          {showError && <div className="col-12 mt-1 error-msg">{showError}</div>}
          <div className="col-12 sach-form">
            <div className="icon-group-title mb-0">{step4 && step4.goal && step4.goal.title}</div>
            <p className="icon-group-para">{step4 && step4.goal && step4.goal.subTitle}</p>
            <ul className="icon-group" onClick={(event) => onChangeAspirationSelection(event)}>
              {step4 &&
                step4.goal &&
                step4.goal.type.map((item, index) => (
                  <li key={index}>
                    <input type="checkbox" className="btn-check" name="Goals" id={item.text} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor={item.text}>
                      <img src={item.imageUrl} alt="Goals" />
                      {item.text}
                    </label>
                  </li>
                ))}
              {/* <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Have more money" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Have more money">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Money.svg").default} alt="Goals" />
                  {step4 && step4.money && step4.money.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Be productive" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Be productive">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Productive.svg").default} alt="Goals" />
                  {step4 && step4.productive && step4.productive.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Build strong family" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Build strong family">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Family.svg").default} alt="Goals" />
                  {step4 && step4.family && step4.family.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Have a healthy body" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Have a healthy body">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Healthcare.svg").default} alt="Goals" />
                  {step4 && step4.body && step4.body.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Love & be loved" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Love & be loved">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Loved-Ones.svg").default} alt="Goals" />
                  {step4 && step4.loved && step4.loved.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Be happy" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Be happy">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/happy.svg").default} alt="Goals" />
                  {step4 && step4.happy && step4.happy.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Goals" id="Improve social life" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Improve social life">
                  <img src={require("../../static/user/img/icons/signupIcons/goals/Social-Work.svg").default} alt="Goals" />
                  {step4 && step4.life && step4.life.text}
                </label>
              </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form mb-0">
            <div className="icon-group-title">{step4 && step4.area && step4.area.title}</div>
            <ul className="icon-group" onClick={(event) => onChangeAspirationSelection(event)}>
              {step4 &&
                step4.area &&
                step4.area.data.map((item, index) => (
                  <li key={index}>
                    <input type="checkbox" className="btn-check" name="elevate" id={item.name} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor={item.name}>
                      <img src={item.imageUrl} alt="Elevate" />
                      {item.name}
                    </label>
                  </li>
                ))}
              {/* <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Leadership" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Leadership">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Leadership.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Leadership && step4.area.Leadership.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Management" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Management">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Management.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Management && step4.area.Management.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Empthy" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Empthy">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Empathy.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Empthy && step4.area.Empthy.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Planning" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Planning">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Planning.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Planning && step4.area.Planning.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Time-Management" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Time-Management">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Time-Management.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.management && step4.area.management.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Parenting" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Parenting">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Parenting.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Parenting && step4.area.Parenting.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Habits" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Habits">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Habits.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Habits && step4.area.Habits.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Emotions" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Emotions">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Emotions.svg").default} alt="Elevate" />

                  {step4 && step4.area && step4.area.Emotions && step4.area.Emotions.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Nutrition" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Nutrition">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Nutrition.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Nutrition && step4.area.Nutrition.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Mindset" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Mindset">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Mindset.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Mindset && step4.area.Mindset.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Self Confidence" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Self Confidence">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Self-Confidance.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Confidence && step4.area.Confidence.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Self Care" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Self Care">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Self-Care.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Care && step4.area.Care.text}
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Communication" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Communication">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Communication.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Communication && step4.area.Communication.text}

                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="elevate" id="Exercise" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Exercise">
                  <img src={require("../../static/user/img/icons/signupIcons/elevate/Exercise.svg").default} alt="Elevate" />
                  {step4 && step4.area && step4.area.Exercise && step4.area.Exercise.text}

                </label>
              </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form">
            <div className="icon-group-title">{step4 && step4.categoriesIssues && step4.categoriesIssues.title}</div>
            <ul className="icon-group" onClick={(event) => onChangeAspirationSelection(event)}>
              {step4 &&
                step4.categoriesIssues &&
                step4.categoriesIssues.category &&
                step4.categoriesIssues.category.map((item, index) => (
                  <li key={index}>
                    <input type="checkbox" className="btn-check" name="Issue" id={item && item.text} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor={item && item.text}>
                      <img src={item && item.imageUrl} alt="Issue" />
                      {item && item.text}
                    </label>
                  </li>
                ))}
              {/* <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Birth" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Birth">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Birth.svg").default} alt="Issue" />
                  Birth
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Ageing" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Ageing">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Ageing.svg").default} alt="Issue" />
                  Ageing
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Sickness" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Sickness">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Sickness.svg").default} alt="Issue" />
                  Sickness
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Dissociation from loved ones" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Dissociation from loved ones">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Loved-Ones.svg").default} alt="Issue" />
                  Dissociation from loved ones
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Not to get what one wants" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Not to get what one wants">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Want.svg").default} alt="Issue" />
                  Not to get what one wants
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Desire of sensual pleasure" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Desire of sensual pleasure">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Pleasure.svg").default} alt="Issue" />
                  Desire of sensual pleasure
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Desire to get rid off" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Desire to get rid off">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Desire-2.svg").default} alt="Issue" />
                  Desire to get rid off
                </label>
              </li>
              <li>
                <input type="checkbox" className="btn-check" name="Issue" id="Desire to become" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Desire to become">
                  <img src={require("../../static/user/img/icons/signupIcons/issues/Desire-1.svg").default} alt="Issue" />
                  Desire to become
                </label>
              </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form">
            <div className="icon-group-title">
              {step4 && step4.sachTime && step4.sachTime.title} <img src={require("../../static/user/img/sach-icon.svg").default} alt="Sach" />
            </div>
            <ul className="icon-group" onClick={(event) => onChangeSelection(event)}>
              {step4 &&
                step4.sachTime &&
                step4.sachTime.timing &&
                step4.sachTime.timing.map((item, index) => (
                  <li key={index}>
                    <input type="radio" className="btn-check" name="Days" id={item && item.text} autoComplete="off" />
                    <label className="btn btn-outline-primary" htmlFor={item && item.text}>
                      <img src={item && item.imageUrl} alt="Morning" />
                      {item && item.text}
                    </label>
                  </li>
                ))}
              {/* <li>
                <input type="radio" className="btn-check" name="Days" id="Daytime" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Daytime">
                  <img src={require("../../static/user/img/icons/signupIcons/days/Afternoon.svg").default} alt="Afternoon" />
                  Daytime
                </label>
              </li>
              <li>
                <input type="radio" className="btn-check" name="Days" id="Evening" autoComplete="off" />
                <label className="btn btn-outline-primary" htmlFor="Evening">
                  <img src={require("../../static/user/img/icons/signupIcons/days/Evening.svg").default} alt="Evening" />
                  Evening
                </label>
              </li> */}
            </ul>
          </div>
          <div className="col-12 sach-form mb-5">
            <div className="icon-group-title">
              {step4 && step4.spendTime && step4.spendTime.title}
              <img src={require("../../static/user/img/sach-icon.svg").default} alt="Sach" />
            </div>
            <div className="row">
              <div className="col-12 sach-form my-0">
                <input type="number" min="0" className="form-control ducation-input" id="spendDuration" maxLength="3" onChange={(event) => onChangeSignUpform(event)} />
                <label className="ducation-mins">{step4 && step4.spendTime && step4.spendTime.mins}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 sach-form">
            <a href="javascript:void(0);" className="btn btn-sach btn-sach-linear me-3" onClick={() => onSubmitSignUp("SKIP")}>
              {step4 && step4.spendTime && step4.spendTime.skip}
            </a>
            <a href="javascript:void(0);" className="btn btn-sach bg-sach-dark px-4" onClick={() => onSubmitSignUp("NEXT")}>
              {step4 && step4.spendTime && step4.spendTime.next}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const stepFunMapping = {
    1: renderInitalForm,
    2: renderVerifyAccount,
    3: renderBasicInfoForm,
    4: renderAspirationsForm,
  };

  const renderCurrStepData = (step) => {
    return stepFunMapping[step] && stepFunMapping[step]();
  };

  return (
    <>
      <span className="modal-close" onClick={showPopup}>
        <i className="bi bi-x"></i>
      </span>
      <div className="login-modal py-3">
        <div className="container-fluid">
          <div className="row h-100">
            <div className="col-lg-4 col-md-4">
              <div className="loginModal-img signUpModal-img">
                <h4 className="title text-light">{carouselSection && parseHtml(carouselSection.title)}</h4>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 align-self-center">
              <div className="row">
                <div className="col-lg-10 col-md-10 offset-lg-1 offset-md-1 mb-4">
                  <ul className="signUpSteps">
                    <li id="stepIndicator-1" className={currStep === 1 ? "active" : currStep > 1 ? "done" : ""}>
                      <i>1</i>
                      <span>{carouselSection && carouselSection.step1}</span>
                    </li>
                    <li id="stepIndicator-2" className={currStep === 2 ? "active" : currStep > 2 ? "done" : ""}>
                      <i>2</i>
                      <span>{carouselSection && carouselSection.step2}</span>
                    </li>
                    {fromEvent == -1 && (
                      <li id="stepIndicator-3" className={currStep === 3 ? "active" : currStep > 3 ? "done" : ""}>
                        <i>3</i>
                        {/* <i className="bi bi-check-lg"></i> */}
                        <span>{carouselSection && carouselSection.step3}</span>
                      </li>
                    )}
                    {fromEvent == -1 && (
                      <li id="stepIndicator-4" className={currStep === 4 ? "active" : currStep > 4 ? "done" : ""}>
                        <i>4</i>
                        <span>{carouselSection && carouselSection.step4}</span>
                      </li>
                    )}
                  </ul>
                </div>
                {renderCurrStepData(currStep) || <div></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
