import axios from "axios";
import { handleError, handleSuccess } from "../common";
import { POST_CONTRIBUTE, POST_CONTRIBUTE_CREATE_PAYMENT, GET_COUNTRIES, POST_CONTRIBUTE_CHECK_PAYMENT, GET_STATES, GET_CITIES, GET_PIN, GET_CONTRIBUTE_DATA, GET_CONTRIBUTE_COUNT } from "../../constants";
import { getCommonApiHeader } from "../../../utils/util";

export const postForm = (data) => {
  
    return (dispatch, getState) => {
      dispatch(getContributeDataRequest());
      axios
        .post(POST_CONTRIBUTE, data)
        .then((response) => {
          if (response) {
            dispatch(handleSuccess({...response?.data, message: 'Your query has been forwarded to respective team'}));
          }
          dispatch(getContributeDataRespond(response?.data));
        })
        .catch((err) => {
           dispatch(handleError(err));
        });
    };
  };

  export const createPaymentOrder = (data) => {
    return (dispatch, getState) => {
      dispatch(getCreatePaymentDataRequest());
      axios
        .post(POST_CONTRIBUTE_CREATE_PAYMENT, data, 
          {
            headers: {
              ...getCommonApiHeader(),
            },
        })
        .then((response) => {
            dispatch(handleSuccess(response?.data));
            dispatch(getCreatePaymentDataRespond(response?.data));
        })
        .catch((err) => {
          dispatch(handleError(err));
        });
    };
  };

  export const checkPaymentStatus = (data) => {
    return (dispatch, getState) => {
      dispatch(checkPaymentStatusRequest());
      axios
        .put(POST_CONTRIBUTE_CHECK_PAYMENT, data,   { headers: {
          ...getCommonApiHeader(),
        },
    })
        .then((response) => {
            dispatch(checkPaymentStatusResponse(response?.data));
        })
        .catch((err) => {
          dispatch(handleError(err?.response?.data || err));
        });
    };
  };
  
export const getContributeData = (params) => {
  return (dispatch, getState) => {
    dispatch(getContributeDataRequest());
    axios
      .get(GET_CONTRIBUTE_DATA, {
        headers: {
          ...getCommonApiHeader(),
        },
        params,
  
      })
      .then((response) => {
        dispatch(getContributeDataRespond(response?.data));
      })
      .catch((err) => { 
        dispatch(handleError({ ...err, message: "Failed to fetch data" }));
      });
  };
};

export const getContributeCount = (params) => {
  return (dispatch, getState) => {
    
    dispatch(getContributeCountRequest());
    axios
      .get(GET_CONTRIBUTE_COUNT, {
        headers: {
          ...getCommonApiHeader(),
        },
        params,
  
      })
      .then((response) => {
        
        
        dispatch(getContributeCountRespond(response?.data));
      })
      .catch((err) => { 
        dispatch(handleError({ ...err, message: "Failed to fetch data" }));
      });
  };
};

  export const getCountries = () => {
    return (dispatch, getState) => {
      axios
        .get(GET_COUNTRIES)
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "Contribute_GET_COUNTRIES_DATA",
              data: response.data,
            });
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
        });
    };
  };
  export const getStates = (countryId) => {
    return (dispatch, getState) => {
      axios
        .get(GET_STATES, {
          headers: {
            ...getCommonApiHeader(),
          },
          params: { country_id: countryId },
        })
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "Contribute_GET_STATES_DATA",
              data: response.data,
            });
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
        });
    };
  };
  export const getCities = (stateId) => {
    return (dispatch, getState) => {
      axios
        .get(GET_CITIES,{
          headers: {
            ...getCommonApiHeader(),
          },
          params: { state_id: stateId }
        })
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "Contribute_GET_CITIES_DATA",
              data: response.data,
            });
          }
        })
        .catch((err) => {
          dispatch(handleError(err));
        });
    };
  };

  export const getPin = (pinCode) => {
    return (dispatch, getState) => {
      axios
        .get(GET_PIN, {
          headers: {
            ...getCommonApiHeader(),
          },
          params: { pincode: pinCode }
        })
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "Contribute_GET_PIN_DATA",
              data: response.data,
            });
          }
        })
        .catch((err) => {
          dispatch(handleError(err?.response?.data || err));
        });
    };
  };
export const checkPaymentStatusRequest = () => {
  return {
    type: "Contribute_Payment_status_REQUESTED",
  };
}
export const checkPaymentStatusResponse = (data) => {
  return {
    type: "Contribute_Payment_status_RESPONSE",
    data
  };
}
  export const getContributeDataRequest = (data) => {
    return {
      type: "Contribute_Data_REQUESTED",
    };
  };

  export const getContributeCountRequest = (data) => {
    return {
      type: "Contribute_COUNT_REQUESTED",
    };
  };
  export const getCreatePaymentDataRequest = (data) => {
    return {
      type: "Contribute_CREATE_Data_REQUESTED",
    };
  };
  
  export const getContributeDataRespond = (data) => {
    return {
      type: "Contribute_DATA_RESPONSE",
      data: data,
    };
  };
    export const getCreatePaymentDataRespond = (data) => {
      return {
        type: "Contribute_CREATE_PAYMENT_ORDER",
        data: data,
      };
  };

  export const getContributeCountRespond = (data) => {
    return {
      type: "Contribute_COUNT_RESPONSE",
      data: data,
    };
  };
  